import * as React from 'react';

const uiContext = React.createContext();

function useUi() {
  const [initialKeyword, setInitialKeyword] = React.useState('');
  const [navKeyword, setNavKeyword] = React.useState('');
  const [toast, setToast] = React.useState({
    open: false,
    message: '',
    severity: 'success',
  });

  return {
    initialKeyword,
    setInitialKeyword,
    navKeyword,
    setNavKeyword,
    toast,
    setToast,
    closeToast() {
      const newToast = toast;
      newToast.open = false;
      setToast({
        open: newToast.open,
        message: newToast.message,
        severity: newToast.severity,
      });
    },
  };
}

export function UiProvider({ children }) {
  const data = useUi();

  return <uiContext.Provider value={data}>{children}</uiContext.Provider>;
}

export default function UiConsumer() {
  return React.useContext(uiContext);
}
