import * as React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function StatPanel(props) {
  return (
    <Paper
      elevation={0}
      sx={{
        textAlign: 'left',
        padding: '20px',
        margin: '20px',
        minHeight: props.minHeight || '180px',
      }}
      variant='outlined'
    >
      <Stack spacing={props.spacing || 3}>
        <Typography variant='body'>{props.title}</Typography>
        {props.hideStat === 'true' ? (
          <></>
        ) : (
          <Typography variant='h4' color={props.color || 'primary.main'}>
            {props.stat || '-'}
          </Typography>
        )}

        {props.component || ''}
        {props.hideSubStat === 'true' ? (
          <></>
        ) : (
          <Typography variant='body'>{props.substat}</Typography>
        )}
      </Stack>
    </Paper>
  );
}

export default StatPanel;
