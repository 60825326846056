import * as React from 'react';

import { useNavigate } from 'react-router-dom';

import AuthService from '../api/auth.js';

const authContext = React.createContext();

function useAuth() {
  const [authed, setAuthed] = React.useState(false);
  const [user, setUser] = React.useState({});

  const navigate = useNavigate();

  const handleLogin = async (email, password, code, flow) => {
    await AuthService.getToken(email, password, code, flow).then((response) => {
      if (response.access_token) {
        localStorage.setItem('user', JSON.stringify(response));
        setUser(response.user);
        setAuthed(true);
      } else {
        setUser({});
        setAuthed(false);
      }
    });
  };

  // load the local storage user on app load/refresh
  React.useEffect(() => {
    if (localStorage.getItem('user') !== null) {
      AuthService.getCurrentUser().then((response) => {
        if (response.data.email) {
          setUser(response.data);
          setAuthed(true);
        } else {
          setUser({});
          setAuthed(false);
        }
      });
    } else {
      setUser({});
      setAuthed(false);
    }
  }, []);

  return {
    authed,
    user,
    login(data) {
      return handleLogin(data);
    },
    getUser() {
      return new Promise((res) => {
        AuthService.getCurrentUser().then((response) => {
          if (response.data.email) {
            var cachedUser = JSON.parse(localStorage.getItem('user'));
            cachedUser.user = response.data;
            localStorage.setItem('user', JSON.stringify(cachedUser));
            setUser(response.data);
            setAuthed(true);
          } else {
            setUser({});
            setAuthed(false);
            navigate('/login');
          }
        });
        res();
      });
    },
    logout() {
      return new Promise((res) => {
        localStorage.removeItem('user');
        setUser({});
        setAuthed(false);
        res();
      });
    },
    putUser(id, data) {
      return new Promise((res) => {
        const r = AuthService.putUser(id, data).then((response) => {
          // update state user and local storage user objects
          var cachedUser = JSON.parse(localStorage.getItem('user'));
          cachedUser.user = response.data;
          localStorage.setItem('user', JSON.stringify(cachedUser));
          setUser(response.data);
          return response;
        });
        res(r);
      });
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}

export function RequireAuth({ children }) {
  const { authed } = useAuth();
  const navigate = useNavigate();

  return authed === true ? children : navigate('/login');
}
