import * as React from 'react';

//MUI
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import useUi from '../context/uiContext.js';

function GlobalToast(props) {
  const { toast, closeToast } = useUi();

  return (
    <Snackbar
      open={toast.open}
      autoHideDuration={6000}
      onClose={closeToast}
      sx={{ width: '30%' }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert onClose={closeToast} severity={toast.severity}>
        {toast.message}
      </Alert>
    </Snackbar>
  );
}

export default GlobalToast;
