import * as React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import { Link } from 'react-router-dom';

import dayjs from 'dayjs';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import twitterLogo from '../images/twitter32.png';
import redditLogo from '../images/reddit32.png';
import mastodonLogo from '../images/mastodon32.png';

import useUi from '../context/uiContext.js';

function Keyword(props) {
  const { setNavKeyword } = useUi();

  const generateCounts = (arr, id) => {
    for (const item in arr) {
      if (arr[item].keyword === id) {
        return arr[item].new_mentions;
      }
    }
  };

  return (
    <Paper
      elevation={1}
      sx={{
        textAlign: 'left',
        padding: '16px',
        marginLeft: '20px',
        minHeight: props.minHeight || '140px',
      }}
      variant='outlined'
    >
      <Stack spacing={props.spacing || 3}>
        <Stack direction='row'>
          {props.data.reddit === true ? (
            <Box
              sx={{
                height: 'auto',
                width: '32px',
                textAlign: 'center',
                marginLeft: '0px',
              }}
            >
              <img alt='Reddit' src={redditLogo}></img>
            </Box>
          ) : (
            <></>
          )}
          {props.data.twitter === true ? (
            <Box
              sx={{
                height: 'auto',
                width: '32px',
                textAlign: 'center',
                marginLeft: '3px',
              }}
            >
              <img alt='Twitter' src={twitterLogo}></img>
            </Box>
          ) : (
            <></>
          )}
          {props.data.mastodon === true ? (
            <Box
              sx={{
                height: 'auto',
                width: '32px',
                textAlign: 'center',
                marginLeft: '3px',
              }}
            >
              <img alt='Mastodon' src={mastodonLogo}></img>
            </Box>
          ) : (
            <></>
          )}

          <Box
            sx={{
              height: 'auto',
              width: '100%',
              textAlign: 'right',
            }}
          >
            <Typography variant='body2' sx={{ fontSize: 14 }}>
              {dayjs.unix(props.data.created_date).format('MM/DD/YYYY')}
            </Typography>
          </Box>
        </Stack>
        <Stack direction='row'>
          <Typography
            sx={{ textAlign: 'left', width: '70%', height: '100%' }}
            variant='h6'
            color='primary'
          >
            {props.data.keyword}
          </Typography>
          <Stack sx={{ width: '30%', textAlign: 'right' }}>
            <Typography sx={{ textAlign: 'right', fontSize: 12 }} variant='p2'>
              New Mentions
            </Typography>
            <div>
              <Link
                to={'/mentions'}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Typography
                  button
                  color='primary'
                  sx={{ textAlign: 'right' }}
                  variant='h6'
                  onClick={() => setNavKeyword(props.data._id)}
                >
                  {generateCounts(props.counts, props.data._id)}
                </Typography>
              </Link>
            </div>
          </Stack>
        </Stack>

        <Stack direction='row'>
          <Typography sx={{ width: '100%', margin: 'auto' }} variant='caption'>
            Exact Match: {props.data.exact_match === true ? 'On' : 'Off'}
          </Typography>
          <Box
            sx={{
              width: '80%',
              textAlign: 'right',
              padding: '0px',
              margin: '0px',
            }}
          >
            <IconButton
              color='primary'
              onClick={() => props.editClick(props.data)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color='error'
              onClick={() => props.deleteClick(props.data)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default Keyword;
