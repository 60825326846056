import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

function DetailPanel(props) {
  const generateUserLink = (source, author) => {
    if (source === 'Reddit') {
      return (
        <Link href={'https://reddit.com/u/' + author} target='_blank'>
          {'u/' + author}
        </Link>
      );
    }
    if (source === 'Twitter') {
      return (
        <Link href={'https://twitter.com/' + author} target='_blank'>
          {'@' + author}
        </Link>
      );
    } else {
      return (
        <Link href={'https://' + author} target='_blank'>
          {'@' + author.split('@')[1]}
        </Link>
      );
    }
  };

  return (
    <Box
      sx={{
        height: 'auto',
        textAlign: 'left',
        padding: '16px',
        marginLeft: '3%',
        marginRight: '3%',
        marginTop: '10px',
        marginBottom: '10px',
        borderStyle: 'none none none solid',
        borderColor: 'lightgray',
        borderWidth: '2px',
        backgroundColor: 'grey.100',
      }}
    >
      <Grid container>
        <Grid item xs={2}>
          <Stack>
            <Typography variant='overline' sx={{ fontSize: 10 }}>
              Author
            </Typography>
            <Typography variant='body'>
              {generateUserLink(props.source, props.author)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={10}>
          <Stack>
            <Typography variant='overline' sx={{ fontSize: 10 }}>
              Full Source Text
            </Typography>
            <Typography variant='body'>
              {props.source === 'Twitter'
                ? props.text
                : props.full_text === ''
                ? props.text
                : props.full_text}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Typography variant='body'></Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DetailPanel;
