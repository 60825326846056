import * as React from 'react';

import { isEmail, isLength } from 'validator';
import { useStateValidator } from 'react-use';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { Link, useNavigate, Navigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactGA from 'react-ga4';

import AuthService from '../../api/auth.js';
import useAuth from '../../context/authContext.js';
import useUi from '../../context/uiContext.js';

export default function Register() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');

  const [emailValid] = useStateValidator(email, (email) => isEmail(email));
  const [passwordValid] = useStateValidator(password, (password) =>
    isLength(password, { min: 8, max: undefined })
  );

  const navigate = useNavigate();
  const { authed } = useAuth();
  const captchaRef = React.useRef();
  const { setToast } = useUi();

  const invalid = () => {
    if (!emailValid) {
      setToast({
        open: true,
        message: 'Invalid email address',
        severity: 'error',
      });
    } else if (!passwordValid) {
      setToast({
        open: true,
        message: 'Invalid password length',
        severity: 'error',
      });
    } else if (password !== password2) {
      setToast({
        open: true,
        message: 'Passwords do not match',
        severity: 'error',
      });
    }
  };

  const handleSubmit = () => {
    if (!emailValid || !passwordValid || password !== password2) {
      invalid();
    } else {
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      AuthService.register({
        email: email,
        password: password,
        token: token,
      }).then((res) => {
        if (res.status === 200) {
          navigate('/login');
          setToast({
            open: true,
            message: 'Registration successful!  Please log in.',
            severity: 'success',
          });
        } else {
          setToast({
            open: true,
            message: 'There was an error creating your account.',
            severity: 'error',
          });
          setEmail('');
          setPassword('');
          setPassword2('');
        }
      });
    }
  };

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  return (
    <div>
      {authed ? (
        <Navigate to='/dashboard' />
      ) : (
        <Grid container>
          <Grid item xs={0} md={4} />
          <Grid item xs={12} md={4}>
            <Stack
              spacing={0}
              sx={{
                minHeight: '100vh',
              }}
            >
              <Paper
                sx={{
                  padding: '20px',
                  marginTop: '40px',
                  marginLeft: '20px',
                  marginRight: '20px',
                }}
              >
                <Stack spacing={2}>
                  <Typography variant='h4'>Create an Account</Typography>
                  <TextField
                    id='email'
                    label='Email'
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    autoFocus
                    required
                  />
                  <TextField
                    id='password'
                    label='Password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                  />
                  <TextField
                    id='password2'
                    label='Confirm Password'
                    type='password'
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    fullWidth
                    required
                  />
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      ref={captchaRef}
                    />
                  </Box>
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ height: '50px' }}
                    onClick={handleSubmit}
                  >
                    Register
                  </Button>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={6}
                      sx={{ textAlign: 'right', padding: '10px' }}
                    >
                      <Link to={'/login'} style={{ color: 'inherit' }}>
                        <Typography variant='body2'>
                          Have an Account?
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'left' }}>
                      <Link to={'/forgot'} style={{ color: 'inherit' }}>
                        <Typography variant='body2'>
                          Forgot Password?
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </Stack>
              </Paper>
            </Stack>
          </Grid>
          <Grid item xs={0} md={4} />
        </Grid>
      )}
    </div>
  );
}
