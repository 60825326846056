import * as React from 'react';

import { isEmail, isLength } from 'validator';
import { useStateValidator } from 'react-use';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { Link, useNavigate, Navigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

import useAuth from '../../context/authContext.js';

export default function Login() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const [emailValid] = useStateValidator(email, (email) => isEmail(email));
  const [passwordValid] = useStateValidator(password, (password) =>
    isLength(password, { min: 8, max: undefined })
  );

  const { login, authed } = useAuth();
  const navigate = useNavigate();

  const invalid = () => {
    if (!emailValid) {
      setMessage('Invalid email address.');
      setOpen(true);
    } else if (!passwordValid) {
      setMessage('Invalid password length');
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogin = (data) => {
    if (data.flow === 'google') {
      login({
        email: email,
        password: password,
        code: data.credential,
        flow: data.flow,
      }).then(() => {
        navigate('/dashboard');
      });
    } else if (!emailValid || !passwordValid) {
      invalid();
    } else {
      login({
        email: email,
        password: password,
        flow: data.flow,
        code: data.code,
      }).then(() => {
        navigate('/dashboard');
      });
    }
  };

  return (
    <div>
      {authed ? (
        <Navigate to='/dashboard' />
      ) : (
        <Grid container>
          <Grid item xs={0} md={4} />
          <Grid item xs={12} md={4}>
            <Stack
              spacing={0}
              sx={{
                minHeight: '100vh',
              }}
            >
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity='error'
                  sx={{ width: '100%' }}
                >
                  {message}
                </Alert>
              </Snackbar>
              <Paper
                sx={{
                  padding: '20px',
                  marginTop: '40px',
                  marginLeft: '20px',
                  marginRight: '20px',
                }}
              >
                <Stack spacing={2}>
                  <Typography variant='h4'>Mention List</Typography>
                  <TextField
                    id='email'
                    label='Email'
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    autoFocus
                    required
                  />
                  <TextField
                    id='password'
                    label='Password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                  />
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ height: '50px' }}
                    onClick={() => handleLogin({ flow: 'local', code: '' })}
                  >
                    Login
                  </Button>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={6}
                      sx={{ textAlign: 'right', padding: '10px' }}
                    >
                      <Link to={'/register'} style={{ color: 'inherit' }}>
                        <Typography variant='body2'>
                          Need an Account?
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: 'left' }}>
                      <Link to={'/forgot'} style={{ color: 'inherit' }}>
                        <Typography variant='body2'>
                          Forgot Password?
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid>
                </Stack>
              </Paper>
              <Divider
                spacing={2}
                sx={{
                  padding: '20px',
                  color: 'text.secondary',
                  marginTop: '20px',
                }}
              >
                <Typography variant='overline'>Other Options</Typography>
              </Divider>
              <Box display='flex' alignItems='center' justifyContent='center'>
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    handleLogin({
                      credential: credentialResponse.credential,
                      flow: 'google',
                    });
                  }}
                  onError={() => {
                    setMessage('Google Login Failed');
                    setOpen(true);
                  }}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={0} md={4} />
        </Grid>
      )}
    </div>
  );
}
