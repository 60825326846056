import * as React from 'react';

import { isLength } from 'validator';
import { useStateValidator } from 'react-use';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { useNavigate, Navigate, useParams } from 'react-router-dom';

import AuthService from '../../api/auth.js';
import useAuth from '../../context/authContext.js';

export default function Register() {
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('error');

  const [passwordValid] = useStateValidator(password, (password) =>
    isLength(password, { min: 8, max: undefined })
  );

  let { token } = useParams();
  const navigate = useNavigate();
  const { authed } = useAuth();

  const invalid = () => {
    if (!passwordValid) {
      setSeverity('error');
      setMessage('Invalid password length');
      setOpen(true);
    } else if (password !== password2) {
      setSeverity('error');
      setMessage('Passwords do not match');
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!passwordValid || password !== password2) {
      invalid();
    } else {
      AuthService.resetForgotPassword(token, {
        password: password,
        password2: password2,
      }).then((res) => {
        if (res.status === 200) {
          navigate('/login');
          setSeverity('success');
          setMessage('Success, please login with your new password.');
          setOpen(true);
        } else {
          setMessage('Token invalid, please request another.');
          setSeverity('error');
          setOpen(true);
          setPassword('');
          setPassword2('');
        }
      });
    }
  };

  return (
    <div>
      {authed ? (
        <Navigate to='/dashboard' />
      ) : (
        <Grid container>
          <Grid item xs={0} md={4} />
          <Grid item xs={12} md={4}>
            <Stack
              spacing={0}
              sx={{
                minHeight: '100vh',
              }}
            >
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity={severity}
                  sx={{ width: '100%' }}
                >
                  {message}
                </Alert>
              </Snackbar>
              <Paper
                sx={{
                  padding: '20px',
                  marginTop: '40px',
                  marginLeft: '20px',
                  marginRight: '20px',
                }}
              >
                <Stack spacing={2}>
                  <Typography variant='h5'>Create New Password</Typography>
                  <TextField
                    id='password'
                    label='Password'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    required
                  />
                  <TextField
                    id='password2'
                    label='Confirm Password'
                    type='password'
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    fullWidth
                    required
                  />
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ height: '50px' }}
                    onClick={handleSubmit}
                  >
                    Save Password
                  </Button>
                </Stack>
              </Paper>
            </Stack>
          </Grid>
          <Grid item xs={0} md={4} />
        </Grid>
      )}
    </div>
  );
}
