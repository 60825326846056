import * as React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Link from '@mui/material/Link';

import { useNavigate } from 'react-router-dom';

import useAuth from '../../context/authContext.js';

export default function Success() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justifyContent='top'
      style={{ minHeight: '100vh', marginTop: '50px' }}
    >
      <Paper sx={{ width: '80%', padding: '20px' }}>
        <Stack spacing={2}>
          <Typography variant='h4'>Thank you!</Typography>
          <Box sx={{ textAlign: 'center', width: '100%' }}>
            <CheckCircleOutlineIcon
              sx={{ width: '180px', height: '180px', color: 'primary.main' }}
            />
          </Box>
          <Typography variant='body1' sx={{ paddingBottom: '30px' }}>
            Your payment has been processed successfully.{' '}
            <Link
              underline='hover'
              onClick={handleLogout}
              sx={{ cursor: 'pointer' }}
            >
              Please logout for your new account settings to take effect.
            </Link>
          </Typography>
        </Stack>
      </Paper>
    </Grid>
  );
}
