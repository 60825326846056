import * as React from 'react';

import { isEmail } from 'validator';
import { useStateValidator } from 'react-use';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { Navigate } from 'react-router-dom';

import AuthService from '../../api/auth.js';
import useAuth from '../../context/authContext.js';

export default function Forgot() {
  const [email, setEmail] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('error');

  const [emailValid] = useStateValidator(email, (email) => isEmail(email));

  const { authed } = useAuth();

  const invalid = () => {
    if (!emailValid) {
      setSeverity('error');
      setMessage('Invalid email address');
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!emailValid) {
      invalid();
    } else {
      AuthService.forgotPassword({ email: email }).then((res) => {
        if (res.status === 200) {
          setEmail('');
          setSeverity('success');
          setMessage(
            'Thank you for your request, if the account exists an email will be sent with addiitonal instructions.'
          );
          setOpen(true);
        } else {
          setSeverity('error');
          setMessage('An error occured. Please try again.');
          setOpen(true);
          setEmail('');
        }
      });
    }
  };

  return (
    <div>
      {authed ? (
        <Navigate to='/dashboard' />
      ) : (
        <Grid container>
          <Grid item xs={0} md={4} />
          <Grid item xs={12} md={4}>
            <Stack
              spacing={0}
              sx={{
                minHeight: '100vh',
              }}
            >
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity={severity}
                  sx={{ width: '100%' }}
                >
                  {message}
                </Alert>
              </Snackbar>
              <Paper
                sx={{
                  padding: '20px',
                  marginTop: '40px',
                  marginLeft: '20px',
                  marginRight: '20px',
                }}
              >
                <Stack spacing={2}>
                  <Typography variant='h4'>Mention List</Typography>
                  <Typography variant='body1' sx={{ paddingBottom: '20px' }}>
                    To reset your password please enter a valid email below. If
                    your account exists you will be sent additional
                    instructions.
                  </Typography>
                  <TextField
                    id='email'
                    label='Email'
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    autoFocus
                    required
                  />
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{ height: '50px' }}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Stack>
              </Paper>
            </Stack>
          </Grid>
          <Grid item xs={0} md={4} />
        </Grid>
      )}
    </div>
  );
}
