import * as React from 'react';
import '../App.css';

function NoMatch() {
  return (
    <div className='App'>
      <h1>404 - Page Not Found</h1>
    </div>
  );
}

export default NoMatch;
