import axios from 'axios';
import { authHeader, authResetHeader } from './auth-header.js';

import { setResponse } from './_helpers.js';

const API_URL = process.env.REACT_APP_API_URL;

const register = (data) => {
  return axios
    .post(API_URL + '/users/register', data, authHeader())
    .then((res) => {
      return setResponse(res);
    });
};

const forgotPassword = (data) => {
  return axios.post(API_URL + '/users/forgot', data).then((res) => {
    return setResponse(res);
  });
};

const resetPassword = (id, data) => {
  return axios
    .put(API_URL + `/users/password/${id}`, data, authHeader())
    .then((res) => {
      return setResponse(res);
    });
};

const resetForgotPassword = (token, data) => {
  return axios
    .post(API_URL + `/users/password/reset`, data, authResetHeader(token))
    .then((res) => {
      return setResponse(res);
    });
};

//exhange credentials for access token
const getToken = (data) => {
  return axios
    .post(API_URL + '/users/login', {
      ...data,
    })
    .then((response) => {
      return response.data;
    });
};
const logout = () => {
  localStorage.removeItem('user');
};

// get user object
const getCurrentUser = () => {
  return axios.get(API_URL + '/users/account', authHeader()).then((res) => {
    return setResponse(res);
  });
};

// update user profile
const putUser = (id, data) => {
  return axios.put(API_URL + `/users/${id}`, data, authHeader()).then((res) => {
    return setResponse(res);
  });
};

// update user notification
const putNotifications = (id, data) => {
  return axios
    .put(API_URL + `/users/${id}/notifications`, data, authHeader())
    .then((res) => {
      return setResponse(res);
    });
};

const AuthService = {
  register,
  forgotPassword,
  resetPassword,
  resetForgotPassword,
  getToken,
  logout,
  getCurrentUser,
  putUser,
  putNotifications,
};
export default AuthService;
