import * as React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import useAuth from '../context/authContext.js';
import useUi from '../context/uiContext.js';

import ApiService from '../api/api.js';

export default function Contact() {
  const { user } = useAuth();
  const { setToast } = useUi();

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handleSubmit = () => {
    ApiService.postContact({ name: name, email: email, message: message }).then(
      (res) => {
        if (res.status === 200) {
          setToast({
            open: true,
            message: 'Message sent, we will contact you soon.',
            severity: 'success',
          });
        }
      }
    );
  };

  React.useEffect(() => {
    setEmail(user.email || '');
    setName(user.name || '');
  }, [user]);

  return (
    <Stack
      sx={{
        width: '100%',
        height: '1000px',
      }}
    >
      <Paper sx={{ minWidth: '50%', margin: '20px' }}>
        <Typography sx={{ padding: '16px', textAlign: 'left' }}>
          Send us a Message
        </Typography>
        <Divider />
        <Stack spacing={2} sx={{ margin: '30px' }}>
          <TextField
            id='fullname'
            label='Full Name'
            type='text'
            variant='standard'
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            disabled
            required
          />
          <TextField
            id='profile-email'
            label='Email'
            type='email'
            variant='standard'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            disabled
            required
          />
          <TextField
            id='profile-text'
            label='Message'
            type='text'
            variant='outlined'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            autoFocus
            required
            placeholder='Please enter a message...'
            multiline
            rows={10}
          />
        </Stack>
        <Divider />
        <Box
          sx={{
            textAlign: 'right',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingRight: '20px',
          }}
        >
          <Button
            variant='outlined'
            color='primary'
            sx={{ height: '36px', width: '100px' }}
            onClick={handleSubmit}
          >
            Send
          </Button>
        </Box>
      </Paper>
    </Stack>
  );
}
