import * as React from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import { styled } from '@mui/material/styles';

import { Link, Navigate } from 'react-router-dom';

import ReactGA from 'react-ga4';

import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PeopleIcon from '@mui/icons-material/People';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import TuneIcon from '@mui/icons-material/Tune';

import browsing from '../images/browsing.png';
import followers from '../images/followers.png';
import useAuth from '../context/authContext.js';
import useUi from '../context/uiContext.js';

import mastodonLogo from '../images/mastodon50.png';
import redditLogo from '../images/reddit50.png';
import twitterLogo from '../images/twitter50.png';

const Img = styled('img')({
  display: 'block',
  maxHeight: '100%',
});

export default function Landing() {
  const { authed } = useAuth();
  const { initialKeyword, setInitialKeyword, setToast } = useUi();

  const handleSignUp = () => {
    console.log(initialKeyword);
    setToast({ open: true, message: 'Testing Toasts', severity: 'warning' });
  };

  React.useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/landing' });
  }, []);

  return (
    <div>
      {authed ? (
        <Navigate to={'/dashboard'} />
      ) : (
        <Box sx={{ width: '100%', height: '100px' }}>
          <Grid container>
            <Grid
              item
              container
              direction='column'
              alignItems='flex-end'
              justify='flex-end'
              xs={12}
              md={6}
              sx={{
                textAlign: 'left',
                paddingTop: '80px',
                paddingLeft: '20px',
              }}
            >
              <Stack spacing={2}>
                <Stack spacing={0.01} direction='row'>
                  <Box
                    sx={{
                      textAlign: 'center',
                      height: 'auto',
                      width: '60px',
                    }}
                  >
                    <img alt='Mastodon' src={mastodonLogo}></img>
                  </Box>
                  <Box
                    sx={{
                      textAlign: 'center',
                      height: 'auto',
                      width: '60px',
                    }}
                  >
                    <img alt='Twitter' src={twitterLogo}></img>
                  </Box>
                  <Box
                    sx={{
                      textAlign: 'center',
                      height: 'auto',
                      width: '60px',
                    }}
                  >
                    <img alt='Reddit' src={redditLogo}></img>
                  </Box>
                </Stack>
                <Typography
                  variant='overline'
                  color='text.secondary'
                  fontSize={14}
                >
                  Monitor the internet to find your next customer
                </Typography>
                <Typography variant='h3'>Track Mentions. Get Leads.</Typography>
                <FormGroup
                  row
                  sx={{
                    paddingTop: '10px',
                    paddingLeft: '5px',
                    height: '40px',
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} md={8}></Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        placeholder='Enter keyword or phrase'
                        sx={{
                          width: '95%',
                          height: '100px',
                        }}
                        value={initialKeyword}
                        onChange={(e) => setInitialKeyword(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Link
                        to='/register'
                        onClick={() => window.scrollTo(0, 0)}
                        style={{
                          textDecoration: 'none',
                          color: 'inherit',
                        }}
                      >
                        <Button
                          variant='contained'
                          disableElevation
                          sx={{
                            width: '95%',
                            height: '55px',
                          }}
                          onClick={handleSignUp}
                        >
                          Start Tracking
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </FormGroup>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                marginTop: '40px',
                display: { xs: 'flex', md: 'flex' },
                alignItems: 'center',
              }}
            >
              <Stack>
                <Box
                  sx={{
                    width: '100%',
                    marginTop: '120px',
                    display: { xs: 'flex', md: 'none' },
                  }}
                />
                <Img
                  sx={{ width: '100%', height: 'auto' }}
                  alt='mentions'
                  src={browsing}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid
            id='about'
            container
            alignItems='center'
            justifyContent='center'
            sx={{
              height: 'auto',
              padding: '20px',
              marginTop: '20px',
            }}
          >
            <Grid item xs={12} md={8} lg={6}>
              <Stack spacing={2} sx={{ alignItems: 'center' }}>
                <Typography
                  variant='overline'
                  color='text.secondary'
                  sx={{ fontSize: 16 }}
                >
                  Generating leads doesn't have to be complicated.
                </Typography>
                <Typography
                  variant='h4'
                  color='text.secondary'
                  sx={{ paddingBottom: '40px' }}
                >
                  Mention List keeps it simple.
                </Typography>
                <Paper
                  elevation={0}
                  sx={{
                    bgcolor: '#f0eded',
                    minHeight: '50px',
                    width: '100%',
                  }}
                >
                  <Grid container>
                    <Grid item xs={3}>
                      <LooksOneIcon
                        sx={{
                          fontSize: 75,
                          paddingTop: '20px',
                          paddingBottom: '20px',
                          color: 'text.secondary',
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'left',
                      }}
                    >
                      <Typography variant='h6'>
                        Research keywords or phrases about your product with a
                        customized search engine
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper
                  elevation={0}
                  sx={{
                    bgcolor: '#f0eded',
                    minHeight: '50px',
                    width: '100%',
                  }}
                >
                  <Grid container>
                    <Grid item xs={3}>
                      <LooksTwoIcon
                        color='primary.main'
                        sx={{
                          fontSize: 75,
                          paddingTop: '20px',
                          paddingBottom: '20px',
                          color: 'text.secondary',
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'left',
                        paddingRight: '20px',
                      }}
                    >
                      <Typography variant='h6'>
                        Activate keywords on social channels of your choice
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper
                  elevation={0}
                  sx={{
                    bgcolor: '#f0eded',
                    minHeight: '50px',
                    width: '100%',
                  }}
                >
                  <Grid container>
                    <Grid item xs={3}>
                      <Looks3Icon
                        color='primary.main'
                        sx={{
                          fontSize: 75,
                          paddingTop: '20px',
                          paddingBottom: '20px',
                          color: 'text.secondary',
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'left',
                        paddingRight: '20px',
                      }}
                    >
                      <Typography variant='h6'>
                        Relevant mentions get delivered directly to you as top
                        of funnel leads
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
                <Paper
                  elevation={0}
                  sx={{
                    bgcolor: '#f0eded',
                    minHeight: '50px',
                    width: '100%',
                  }}
                >
                  <Grid container>
                    <Grid item xs={3}>
                      <TuneIcon
                        color='primary.main'
                        sx={{
                          fontSize: 75,
                          paddingTop: '20px',
                          paddingBottom: '20px',
                          color: 'text.secondary',
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'left',
                        paddingRight: '20px',
                      }}
                    >
                      <Typography variant='h6'>
                        Leverage text classification and sentiment analysis to
                        gain insights on your product or market
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            id='solutions'
            container
            alignItems='center'
            justifyContent='center'
            spacing={3}
            sx={{
              width: '100%',
              minHeight: '500px',
              bgcolor: '#5AAA95',
              marginTop: '100px',
              paddingTop: '20px',
              paddingBottom: '30px',
            }}
          >
            <Grid item xs={12} md={2}>
              <Typography variant='h4' color='white'>
                Solutions
              </Typography>
            </Grid>
            <Grid item>
              <Paper
                elevation={0}
                sx={{
                  width: '300px',
                  height: '300px',
                  padding: '20px',
                  textAlign: 'center',
                }}
              >
                <Typography variant='h6' sx={{ marginTop: '20px' }}>
                  Generate Leads
                </Typography>
                <RocketLaunchIcon
                  color='primary.main'
                  sx={{
                    fontSize: 75,
                    paddingTop: '20px',
                    paddingBottom: '20px',
                  }}
                />
                <Typography
                  variant='body1'
                  sx={{ width: '220px', marginLeft: '40px' }}
                >
                  Speed up customer acquisition and get to your next sale
                  without a huge marketing budget.
                </Typography>
              </Paper>
            </Grid>
            <Grid item>
              <Paper
                elevation={0}
                sx={{ width: '300px', height: '300px', padding: '20px' }}
              >
                <Typography variant='h6' sx={{ marginTop: '20px' }}>
                  Validate an Idea
                </Typography>
                <CheckCircleOutlineIcon
                  color='primary.main'
                  sx={{
                    fontSize: 75,
                    paddingTop: '20px',
                    paddingBottom: '20px',
                  }}
                />
                <Typography
                  variant='body1'
                  sx={{ width: '220px', marginLeft: '40px' }}
                >
                  Monitor keywords related to your product idea. Validate
                  product market fit before writing a single line of code.
                </Typography>
              </Paper>
            </Grid>
            <Grid item>
              <Paper
                elevation={0}
                sx={{ width: '300px', height: '300px', padding: '20px' }}
              >
                <Typography variant='h6' sx={{ marginTop: '20px' }}>
                  Grow an Audience
                </Typography>
                <PeopleIcon
                  color='primary.main'
                  sx={{
                    fontSize: 75,
                    paddingTop: '20px',
                    paddingBottom: '20px',
                  }}
                />
                <Typography
                  variant='body1'
                  sx={{ width: '220px', marginLeft: '40px' }}
                >
                  Find your target market and organically grow an audience from
                  online communities that could use your product.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            container
            id='why'
            alignItems='center'
            justifyContent='center'
            spacing={3}
            sx={{
              width: '100%',
              minHeight: '400px',
              marginTop: '20px',
              paddingTop: '20px',
              paddingBottom: '20px',
            }}
          >
            <Grid item xs={0} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <Typography color='text.secondary' variant='h4'>
                Why Mention List?
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: '100%',
                  display: { xs: 'flex' },
                }}
              />
              <Img
                sx={{ width: '80%', height: 'auto', margin: 'auto' }}
                alt='followers'
                src={followers}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                spacing={2}
                sx={{ width: '90%', textAlign: 'center', margin: 'auto' }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    backgroundColor: '#f0eded',
                    padding: '20px',
                    textAlign: 'left',
                  }}
                >
                  <Typography
                    variant='button'
                    sx={{ textDecoration: 'underline' }}
                  >
                    Accuracy
                  </Typography>
                  <Typography variant='body1'>
                    We don't just long-poll api's like most tracking tools, we
                    index entire social media sites in real time. This
                    datastream allows us to write rich text searches and deliver
                    much more relavent results with less overhead. Bottom line,
                    more relavent results for you means less wasted time and
                    more value for you.
                  </Typography>
                </Paper>
                <Paper
                  elevation={0}
                  sx={{
                    backgroundColor: '#f0eded',
                    padding: '20px',
                    textAlign: 'left',
                  }}
                >
                  <Typography
                    variant='button'
                    sx={{ textDecoration: 'underline' }}
                  >
                    Platform Risk
                  </Typography>
                  <Typography variant='body1'>
                    We are the first social listening tool to support Mastodon.
                    Not only that, we plan to support many alternative platforms
                    so your digital marketing plans aren't subject to the
                    platform risk associated with any one company.
                  </Typography>
                </Paper>
                <Paper
                  elevation={0}
                  sx={{
                    backgroundColor: '#f0eded',
                    padding: '20px',
                    textAlign: 'left',
                  }}
                >
                  <Typography
                    variant='button'
                    sx={{ textDecoration: 'underline' }}
                  >
                    Innovation
                  </Typography>
                  <Typography variant='body1'>
                    Social listening is great, but what you do with the
                    conversations you find is how you make an impact. We are
                    building technology to give you better insights into your
                    potential clients and markets, as it develops you will have
                    a front row seat to test this technology and tailor it to
                    your needs.
                  </Typography>
                </Paper>
              </Stack>
            </Grid>
          </Grid>

          <Grid
            container
            id='pricing'
            alignItems='center'
            justifyContent='center'
            spacing={3}
            sx={{
              width: '100%',
              minHeight: '500px',
              bgcolor: '#f0eded',
              marginTop: '100px',
              paddingTop: '20px',
              paddingBottom: '30px',
            }}
          >
            <Grid item>
              <Paper
                elevation={0}
                sx={{ width: '300px', height: 'auto', padding: '20px' }}
              >
                <Typography variant='h5' sx={{ marginTop: '20px' }}>
                  Research
                </Typography>
                <Typography
                  color='primary.main'
                  variant='h3'
                  sx={{ paddingTop: '20px', paddingBottom: '10px' }}
                >
                  $10
                </Typography>
                <Typography
                  color='primary.main'
                  variant='body1'
                  sx={{ paddingBottom: '20px' }}
                >
                  per month
                </Typography>
                <Divider sx={{ marginBottom: '20px' }} />
                <Stack spacing={1}>
                  <Typography variant='body2'>5 Keywords</Typography>
                  <Typography variant='body2'>1000 New Mentions</Typography>
                  <Typography variant='body2'>Keyword Research</Typography>
                  <Typography
                    variant='body2'
                    sx={{ textDecoration: 'line-through' }}
                  >
                    Saved Mentions
                  </Typography>
                  <Typography
                    variant='body2'
                    sx={{ textDecoration: 'line-through' }}
                  >
                    Data Export
                  </Typography>
                </Stack>
                <Link
                  to='/register'
                  onClick={() => window.scrollTo(0, 0)}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <Button
                    variant='contained'
                    sx={{ marginTop: '20px', width: '80%' }}
                  >
                    Start Now
                  </Button>
                </Link>
              </Paper>
            </Grid>
            <Grid item>
              <Paper
                elevation={0}
                sx={{ width: '300px', height: 'auto', padding: '20px' }}
              >
                <Typography variant='h5' sx={{ marginTop: '20px' }}>
                  Startup
                </Typography>
                <Typography
                  color='primary.main'
                  variant='h3'
                  sx={{ paddingTop: '20px', paddingBottom: '10px' }}
                >
                  $25
                </Typography>
                <Typography
                  color='primary.main'
                  variant='body1'
                  sx={{ paddingBottom: '20px' }}
                >
                  per month
                </Typography>
                <Divider sx={{ marginBottom: '20px' }} />
                <Stack spacing={1}>
                  <Typography variant='body2'>20 Keywords</Typography>
                  <Typography variant='body2'>
                    Unlimited New Mentions
                  </Typography>
                  <Typography variant='body2'>Keyword Research</Typography>
                  <Typography variant='body2'>50 Saved Mentions</Typography>
                  <Typography variant='body2'>Data Export</Typography>
                </Stack>
                <Link
                  to='/register'
                  onClick={() => window.scrollTo(0, 0)}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <Button
                    variant='contained'
                    sx={{ marginTop: '20px', width: '80%' }}
                  >
                    Start Now
                  </Button>
                </Link>
              </Paper>
            </Grid>
            <Grid item>
              <Paper
                elevation={0}
                sx={{ width: '300px', height: 'auto', padding: '20px' }}
              >
                <Typography variant='h5' sx={{ marginTop: '20px' }}>
                  Scale
                </Typography>
                <Typography
                  color='primary.main'
                  variant='h3'
                  sx={{ paddingTop: '20px', paddingBottom: '10px' }}
                >
                  $75
                </Typography>
                <Typography
                  color='primary.main'
                  variant='body1'
                  sx={{ paddingBottom: '20px' }}
                >
                  per month
                </Typography>
                <Divider sx={{ marginBottom: '20px' }} />
                <Stack spacing={1}>
                  <Typography variant='body2'>200 Keywords</Typography>
                  <Typography variant='body2'>
                    Unlimited New Mentions
                  </Typography>
                  <Typography variant='body2'>Keyword Research</Typography>
                  <Typography variant='body2'>500 Saved Mentions</Typography>
                  <Typography variant='body2'>Data Export</Typography>
                </Stack>
                <Link
                  to='/register'
                  onClick={() => window.scrollTo(0, 0)}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <Button
                    variant='contained'
                    sx={{ marginTop: '20px', width: '80%' }}
                  >
                    Start Now
                  </Button>
                </Link>
              </Paper>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant='h4'>Pricing</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems='center'
            justifyContent='center'
            spacing={3}
            sx={{
              width: '100%',
              height: '500px',
              marginTop: '10px',
            }}
          >
            <Grid item>
              <Stack>
                <Typography
                  variant='overline'
                  color='text.secondary'
                  fontSize={14}
                >
                  Don't use heavy, complicated tools for digital marketing
                </Typography>
                <Typography
                  variant='h4'
                  sx={{ fontWeight: 600, marginTop: '20px' }}
                >
                  Generate leads with ease.
                </Typography>
                <Link
                  to='/register'
                  onClick={() => window.scrollTo(0, 0)}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  <Button
                    variant='contained'
                    sx={{ marginTop: '30px', height: '50px', width: '80%' }}
                  >
                    Start Tracking Today
                  </Button>
                </Link>
              </Stack>
            </Grid>
          </Grid>
          <Box
            sx={{
              height: 'auto',
              width: '100%',
              bottom: '0px',
            }}
          >
            <Grid
              container
              sx={{
                width: '100%',
                bgcolor: 'primary.main',
                paddingBottom: '30px',
              }}
            >
              <Grid item xs={12} md={4} sx={{ paddingTop: '20px' }}>
                <Typography variant='overline' color='common.white'>
                  Mention List
                </Typography>
                <Typography variant='body2' color='common.white'>
                  &copy; 2022
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} sx={{ paddingTop: '20px' }}>
                <Typography color='common.white' variant='overline'>
                  Links
                </Typography>
                <Grid
                  container
                  alignItems='center'
                  justifyContent='center'
                  color='common.white'
                >
                  <Link
                    to={'/privacy'}
                    onClick={() => window.scrollTo(0, 0)}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    <Typography sx={{ padding: '8px' }} variant='body2'>
                      Privacy
                    </Typography>
                  </Link>
                  <Link
                    to={'/terms'}
                    onClick={() => window.scrollTo(0, 0)}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    <Typography sx={{ padding: '8px' }} variant='body2'>
                      Terms
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} sx={{ paddingTop: '20px' }}>
                <Grid
                  container
                  direction='column'
                  alignItems='center'
                  justifyContent='center'
                >
                  <Typography color='common.white' variant='overline'>
                    Get In Touch
                  </Typography>
                  <FormGroup row color='common.white'>
                    <IconButton
                      onClick={() =>
                        window.open('https://twitter.com/MentionList', '_blank')
                      }
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                    >
                      <TwitterIcon sx={{ color: 'white' }} />
                    </IconButton>
                    <IconButton
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                      onClick={() =>
                        window.open('mailto:support@mentionlist.com')
                      }
                    >
                      <EmailIcon sx={{ color: 'white' }} />
                    </IconButton>
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </div>
  );
}
