import * as React from 'react';
import '../App.css';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

function Privacy() {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        width: '100%',
        paddingTop: '20px',
        paddingBottom: '20px',
        textAlign: 'left',
        bgcolor: '#f0eded',
      }}
    >
      <Paper sx={{ height: 'auto', width: '70%', padding: '40px' }}>
        <Typography variant='h4' sx={{ paddingBottom: '20px' }}>
          Privacy Policy for Mention List
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          At Mention List, one of our main priorities is the privacy of our
          visitors. This Privacy Policy document contains types of information
          that is collected and recorded by Mention List and how we use it.
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us through email at
          support@mentionlist.com
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Data Collection
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          In order to use the Mention List application, the following personal
          data will be collected:
        </Typography>
        <List>
          <ListItem key={1}>
            - Email address - Used to contact users about app updates, and
            billing information.
          </ListItem>
          <ListItem key={2}>
            - Billing information (stored by{' '}
            <a href='https://paddle.com'> Paddle</a>, not accessible to Mention
            List directly) - Used to establish a subscription to enable the
            features of the application.
          </ListItem>
        </List>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Upon creating an account and activating a subscription, users agree to
          the collection of this data.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Log Files
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Mention List follows a standard procedure of using log files. These
          files log visitors when they visit websites. All hosting companies do
          this and a part of hosting services' analytics. The information
          collected by log files include internet protocol (IP) addresses,
          browser type, Internet Service Provider (ISP), date and time stamp,
          referring/exit pages, and possibly the number of clicks. These are not
          linked to any information that is personally identifiable. The purpose
          of the information is for analyzing trends, administering the site,
          tracking users' movement on the website, and gathering demographic
          information.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Cookies and Web Beacons
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Like any other website, Mention List uses 'cookies'. These cookies are
          used to store information including visitors' preferences, and the
          pages on the website that the visitor accessed or visited. The
          information is used to optimize the users' experience by customizing
          our web page content based on visitors' browser type and/or other
          information.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Our Advertising Partners
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Some of advertisers on our site may use cookies and web beacons. Our
          advertising partners include:
        </Typography>
        <List>
          <ListItem key={3}>- Google</ListItem>
        </List>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Each of our advertising partners has their own Privacy Policy for
          their website. For easier access, an updated and hyperlinked resource
          is maintained here:
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Privacy Policies
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of Mention List.
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on Mention List, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Note that Mention List has no access to or control over these cookies
          that are used by third-party advertisers.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Third Party Privacy Policies
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Mention List' Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options. You may find a complete list of these
          Privacy Policies and their links here: Privacy Policy Links.
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites. What Are Cookies?
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Children's Information
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Mention List does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Online Privacy Policy Only
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          This privacy policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in Mention List. This policy is not applicable
          to any information collected offline or via channels other than this
          website.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Consent
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its Terms and Conditions.
        </Typography>
      </Paper>
    </Box>
  );
}

export default Privacy;
