import * as React from 'react';

import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import useAuth from '../context/authContext.js';
import useUi from '../context/uiContext.js';

import AuthService from '../api/auth.js';

export default function Notifications() {
  const { user } = useAuth();
  const { setToast } = useUi();

  const [email, setEmail] = React.useState(false);
  const [updates, setUpdates] = React.useState(false);
  const [frequency, setFrequency] = React.useState('');

  const handleSubmit = () => {
    AuthService.putNotifications(user._id, {
      emailnotify: email,
      emailupdates: updates,
      notifyperiod: frequency,
    }).then((res) => {
      if (res.status === 200) {
        setToast({
          open: true,
          message: 'Saved notification settings',
          severity: 'success',
        });
      } else {
        setToast({
          open: true,
          message: 'Failed to save settings',
          severity: 'error',
        });
      }
    });
  };

  React.useEffect(() => {
    setEmail(user.emailnotify || false);
    setUpdates(user.emailupdates || false);
    setFrequency(user.notifyperiod || '');
  }, [user]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ minWidth: '40%', margin: '20px' }}>
        <Typography sx={{ padding: '16px', textAlign: 'left' }}>
          Email Preferences
        </Typography>
        <Divider />
        <Stack
          spacing={2}
          sx={{ margin: '30px', width: '100%', textAlign: 'center' }}
        >
          <FormGroup sx={{ textAlign: 'left' }}>
            <FormControlLabel
              control={
                <Switch
                  inputProps={{ id: 'email' }}
                  checked={email}
                  onChange={(e) => setEmail(e.target.checked)}
                />
              }
              label='Receive email notifications for new mentions'
            />
          </FormGroup>
          <FormControl sx={{ textAlign: 'left' }}>
            <FormLabel id='email-frequency-radio-buttons'>Frequency</FormLabel>
            <RadioGroup
              row
              aria-labelledby='email-frequency-radio-buttons'
              name='row-radio-buttons-group'
              value={frequency}
              onChange={(e) => setFrequency(e.target.value)}
            >
              <FormControlLabel
                value='hourly'
                control={<Radio />}
                label='Hourly'
              />
              <FormControlLabel
                value='twice'
                control={<Radio />}
                label='Twice a Day'
              />
              <FormControlLabel
                value='daily'
                control={<Radio />}
                label='Daily'
              />
            </RadioGroup>
          </FormControl>
          <FormGroup>
            <FormControlLabel
              sx={{ textAlign: 'left' }}
              control={
                <Switch
                  inputProps={{ id: 'updates' }}
                  checked={updates}
                  onChange={(e) => setUpdates(e.target.checked)}
                />
              }
              label='Receive emails about news and updates'
            />
          </FormGroup>
        </Stack>
        <Divider />

        <Box
          sx={{
            textAlign: 'right',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingRight: '20px',
          }}
        >
          <Button
            variant='outlined'
            color='primary'
            sx={{ height: '36px', width: '100px' }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
