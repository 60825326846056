import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ScienceIcon from '@mui/icons-material/Science';
import MenuIcon from '@mui/icons-material/Menu';
import ChatIcon from '@mui/icons-material/Chat';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import { styled, useTheme } from '@mui/material/styles';

import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';

import useAuth from '../../context/authContext.js';
import useData from '../../context/dataContext.js';
import useUi from '../../context/uiContext.js';

import logo from '../../images/logo_2.png';

const drawerWidth = 240;

const Img = styled('img')({
  display: 'block',
  maxHeight: '100%',
});

export default function MainLayout(props) {
  const { window } = props;
  const theme = useTheme();
  const { authed, logout } = useAuth();
  const { navKeyword, setNavKeyword } = useUi();
  const { getKeywords } = useData();
  const navigate = useNavigate();
  const location = useLocation();
  const [headerText, setHeaderText] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(false);
  const [mentionsOpen, setMentionsOpen] = React.useState(true);
  const { keywords } = useData();

  const handleMentionsClick = () => {
    setMentionsOpen(!mentionsOpen);
  };

  const handleDrawerOpen = (open) => {
    setOpen(!open);
  };

  const drawer = (
    <Box sx={{ height: '100vh' }}>
      <Toolbar>
        <Img src={logo} alt='logo' />
      </Toolbar>
      <Divider />
      <List sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
        <Link
          to={'/dashboard'}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItem button key={'dashboard'} onClick={() => setNavKeyword('')}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={'Dashboard'} />
          </ListItem>
        </Link>
        <Divider />
        <ListItem button key={'mentions'} onClick={handleMentionsClick}>
          <ListItemIcon>
            <ChatIcon />
          </ListItemIcon>
          <ListItemText primary={'Mentions'} />
          {mentionsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {mentionsOpen ? <Divider /> : <></>}
        <Collapse in={mentionsOpen} timeout='auto' unmountOnExit>
          <List component='div' disablePadding sx={{ overflow: 'hidden' }}>
            {keywords.map((keyword) => (
              <div>
                <Link
                  to={'/mentions'}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <ListItem
                    button
                    sx={{ pr: 2, textAlign: 'right' }}
                    onClick={() => setNavKeyword(keyword._id)}
                  >
                    <ListItemText
                      primaryTypographyProps={{ fontSize: '14px' }}
                      primary={keyword.keyword}
                    />
                  </ListItem>
                </Link>
              </div>
            ))}
          </List>
        </Collapse>

        <Divider />
        <Link
          to={'/research'}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItem button key={'research'}>
            <ListItemIcon>
              <ScienceIcon />
            </ListItemIcon>
            <ListItemText primary={'Research'} />
          </ListItem>
        </Link>
        <Divider />
        <Link
          to={'/notifications'}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <ListItem button key={'notifications'}>
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText primary={'Notifications'} />
          </ListItem>
        </Link>
      </List>
      <Divider />
      <List
        sx={{
          position: 'fixed',
          width: 240,
          bottom: 0,
          height: 46,
          textAlign: 'center',
        }}
      >
        <Divider />
        <ListItem key={'footer'}>
          <Grid container>
            <Grid item>
              <Typography variant='subtitle2' sx={{ fontSize: 12 }}>
                &copy; Mention List
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                marginLeft: '18px',
                marginTop: '1px',
              }}
            >
              <Link
                to={'/privacy'}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Typography variant='body2' sx={{ fontSize: 12 }}>
                  Privacy
                </Typography>
              </Link>
            </Grid>
            <Grid item sx={{ marginLeft: '10px' }}>
              <Link
                to={'/terms'}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Typography
                  variant='body2'
                  sx={{ marginLeft: '5px', fontSize: 12, marginTop: '1px' }}
                >
                  Terms
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccount = () => {
    navigate('/account');
    handleClose();
  };

  const handleContact = () => {
    navigate('/contact');
    handleClose();
  };

  React.useEffect(() => {
    if (location.pathname.length > 0) {
      var x = location.pathname.split('/');
      setHeaderText(x[1].charAt(0).toUpperCase() + x[1].slice(1));
    } else {
      setHeaderText('');
    }
  }, [location.pathname]);

  React.useEffect(() => {
    getKeywords();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ textAlign: 'left' }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={() => handleDrawerOpen(open)}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
            {headerText}
          </Typography>
          <IconButton
            size='large'
            aria-label='account of current user'
            aria-controls='menu-appbar'
            aria-haspopup='true'
            onClick={handleMenu}
            color='inherit'
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleAccount}>My Account</MenuItem>
            <Divider />
            <MenuItem onClick={handleContact}>Contact Us</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label='mailbox folders'
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={open}
          onClose={handleDrawerOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            minHeight: '100vh',
            flexDirection: 'column',
            display: { xs: 'none', sm: 'flex' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{
          backgroundColor: '#F5F5F5',
          overflow: 'scroll',
          minHeight: '100vh',
          paddingTop: '86px',
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

/*
<Link
to={'/keywords'}
style={{ textDecoration: 'none', color: 'inherit' }}
>
<ListItem button key={'keywords'}>
  <ListItemIcon>
    <ExploreIcon />
  </ListItemIcon>
  <ListItemText primary={'Keywords'} />
</ListItem>
</Link>
<Divider />
*/
