import * as React from 'react';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import MaterialTable from 'material-table';

import useData from '../context/dataContext.js';
import useAuth from '../context/authContext.js';
import useUi from '../context/uiContext.js';
import ConfirmDialog from '../components/confirmdialog.js';

import twitterLogo from '../images/twitter32.png';
import redditLogo from '../images/reddit32.png';
import mastodonLogo from '../images/mastodon32.png';

import { Navigate } from 'react-router-dom';

function Keywords() {
  const [value, setValue] = React.useState(0);
  const [newKeyword, setNewKeyword] = React.useState('');
  const [reddit, setReddit] = React.useState(false);
  const [twitter, setTwitter] = React.useState(false);
  const [mastodon, setMastodon] = React.useState(false);
  const [exactMatch, setExactMatch] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [editKeyword, setEditKeyword] = React.useState({});
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const { keywords, getKeywords, deleteKeyword, postKeyword, putKeyword } =
    useData();
  const { user } = useAuth();
  const { setToast } = useUi();

  const renderDateColumns = (params) => {
    return <Box>{dayjs(params.created_date * 1000).format('MM/DD/YYYY')}</Box>;
  };

  const renderMatchColumns = (params) => {
    return <Box>{params.exact_match === true ? 'On' : 'Off'}</Box>;
  };

  const renderSourceColumns = (params) => {
    return (
      <Stack direction='row'>
        <Box>
          {params.reddit === true ? (
            <Box sx={{ height: 'auto', width: '32px', textAlign: 'center' }}>
              <img alt='Reddit' src={redditLogo}></img>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Box>
          {params.twitter === true ? (
            <Box
              sx={{
                height: 'auto',
                width: '32px',
                textAlign: 'center',
                marginLeft: '3px',
              }}
            >
              <img alt='Twitter' src={twitterLogo}></img>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Box>
          {params.mastodon === true ? (
            <Box
              sx={{
                height: 'auto',
                width: '32px',
                textAlign: 'center',
                marginLeft: '3px',
              }}
            >
              <img alt='Mastodon' src={mastodonLogo}></img>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Stack>
    );
  };

  const tableColumns = [
    {
      title: 'Keyword',
      field: 'keyword',
      cellStyle: {
        cellWidth: '30%',
      },
    },
    {
      field: '',
      title: 'Active Sources',
      cellStyle: {
        cellWidth: '20%',
      },
      render: renderSourceColumns,
    },
    {
      field: 'created_date',
      title: 'Created',
      cellStyle: {
        cellWidth: '30%',
      },
      render: renderDateColumns,
    },
    {
      field: 'exact_match',
      title: 'Exact Match Filter',
      cellStyle: {
        cellWidth: '20%',
      },
      render: renderMatchColumns,
    },
  ];

  const handleDelete = (row) => {
    deleteKeyword(row._id).then((res) => {
      if (res.status === 200) {
        setToast({
          open: true,
          message: 'Keyword deleted successfully.',
          severity: 'success',
        });
      } else {
        setToast({
          open: true,
          message: res.message,
          severity: 'error',
        });
      }
    });
  };

  const handleDeleteConfirm = (row) => {
    setValue(row);
    setConfirmOpen(true);
  };

  const redditChange = () => {
    setReddit(!reddit);
  };

  const twitterChange = () => {
    setTwitter(!twitter);
  };

  const mastodonChange = () => {
    setMastodon(!mastodon);
  };

  const matchChange = () => {
    setExactMatch(!exactMatch);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setReddit(false);
    setTwitter(false);
    setMastodon(false);
    setNewKeyword('');
    setExactMatch(false);
  };

  const handleModalSave = () => {
    postKeyword({
      keyword: newKeyword,
      reddit: reddit,
      twitter: twitter,
      mastodon: mastodon,
      exact_match: exactMatch,
    }).then((res) => {
      if (res.status === 200) {
        setToast({
          open: true,
          message: 'Keyword created successfully.',
          severity: 'success',
        });
        handleClose();
      } else if (res.status === 403) {
        setToast({
          open: true,
          message: 'Keyword limit exceeded.',
          severity: 'error',
        });
        handleClose();
      } else {
        setToast({
          open: true,
          message: 'Server error, please try again.',
          severity: 'error',
        });
        handleClose();
      }
    });
  };

  // edit Modal - lots to refactor
  const handleEditModalOpen = (row) => {
    setEditKeyword(row);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setEditKeyword({});
  };

  const handleEditModalSave = () => {
    putKeyword(editKeyword._id, editKeyword).then((res) => {
      if (res.status === 200) {
        setToast({
          open: true,
          message: 'Keyword updated.',
          severity: 'success',
        });
      } else {
        setToast({
          open: true,
          message: res.message,
          severity: 'error',
        });
      }
    });
    handleEditModalClose();
  };

  const inputGroupChangeHandler = (event) => {
    setEditKeyword((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  };

  const redditSwitchHandler = (event) => {
    setEditKeyword((prevState) => ({
      ...prevState,
      reddit: event.target.checked,
    }));
  };

  const twitterSwitchHandler = (event) => {
    setEditKeyword((prevState) => ({
      ...prevState,
      twitter: event.target.checked,
    }));
  };

  const mastodonSwitchHandler = (event) => {
    setEditKeyword((prevState) => ({
      ...prevState,
      mastodon: event.target.checked,
    }));
  };

  const exactMatchHandler = (event) => {
    setEditKeyword((prevState) => ({
      ...prevState,
      exact_match: event.target.checked,
    }));
  };

  React.useEffect(() => {
    getKeywords();
  }, []);

  return (
    <div>
      {user.plan === '' ? (
        <Navigate to='/plans' />
      ) : (
        <Box sx={{ width: '100%' }}>
          <Paper
            sx={{
              minHeight: '500px',
              marginLeft: '20px',
              marginRight: '20px',
              marginTop: '20px',
            }}
          >
            <Stack direction='row'>
              <Typography
                variant='h6'
                sx={{
                  color: 'text.secondary',
                  textAlign: 'left',
                  padding: '14px',
                  width: '100%',
                }}
              >
                Manage Keywords
              </Typography>
              <Box
                sx={{
                  textAlign: 'right',
                  width: '100%',
                  paddingTop: '10px',
                  paddingRight: '20px',
                }}
              >
                <Button onClick={handleOpen} variant='contained'>
                  Add Keyword
                </Button>
              </Box>
            </Stack>
            <Divider sx={{ marginTop: '-5px' }} />
            <MaterialTable
              style={{
                boxShadow: 'none',
                border: 'none',
                marginLeft: '20px',
                marginRight: '20px',
              }}
              options={{
                showTitle: false,
                searchFieldAlignment: 'left',
                searchFieldStyle: {
                  marginLeft: '-20px',
                  width: '300px',
                },
                exportButton: true,
                exportAllData: true,
                pageSize: 10,
                pageSizeOptions: [5, 10, 20],
                rowStyle: {
                  fontSize: 15,
                  height: 50,
                },
                maxBodyHeight: '500px',
                padding: 'dense',
                actionsColumnIndex: -1,
                tableLayout: 'auto',
              }}
              columns={tableColumns}
              data={keywords || []}
              actions={[
                {
                  icon: 'edit',
                  iconProps: { color: 'primary' },
                  tooltip: 'Edit Keyword',
                  onClick: (event, rowData) => handleEditModalOpen(rowData),
                },
                {
                  icon: 'delete',
                  iconProps: { color: 'error' },
                  tooltip: 'Delete Keyword',
                  onClick: (event, rowData) => handleDeleteConfirm(rowData),
                },
              ]}
            />
          </Paper>
          <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box
              sx={{
                position: 'absolute',
                top: '30%',
                left: '40%',
                width: '30%',
              }}
            >
              <Paper>
                <Typography
                  id='modal-modal-title'
                  variant='h6'
                  component='h2'
                  sx={{ padding: '20px' }}
                >
                  Create a Keyword
                </Typography>
                <Divider />
                <Stack spacing={2} sx={{ padding: '20px' }}>
                  <TextField
                    id='keyword'
                    label='Keyword or Phrase'
                    type='text'
                    variant='standard'
                    value={newKeyword}
                    onChange={(e) => setNewKeyword(e.target.value)}
                    fullWidth
                    autoFocus
                    required
                  />
                  <Typography
                    id='modal-modal-title'
                    variant='caption'
                    component='body'
                  >
                    example: "mentionlist.com"
                  </Typography>
                  <Typography
                    id='add-modal-settings-section'
                    variant='body2'
                    sx={{ marginTop: '40px' }}
                  >
                    Settings
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          inputProps={{ id: 'exact' }}
                          checked={exactMatch}
                          onChange={matchChange}
                        />
                      }
                      label='Exact Match Only'
                    />
                  </FormGroup>
                  <Divider />
                  <Typography
                    id='add-modal-sources'
                    variant='body2'
                    sx={{ marginTop: '30px' }}
                  >
                    Sources (where to look)
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch checked={reddit} onChange={redditChange} />
                      }
                      label='Reddit'
                    />
                    <FormControlLabel
                      control={
                        <Switch checked={twitter} onChange={twitterChange} />
                      }
                      label='Twitter'
                    />
                    <FormControlLabel
                      control={
                        <Switch checked={mastodon} onChange={mastodonChange} />
                      }
                      label='Mastodon'
                    />
                  </FormGroup>
                </Stack>
                <Divider sx={{ marginTop: '20px' }} />
                <Box
                  sx={{
                    textAlign: 'right',
                    padding: '10px',
                  }}
                >
                  <Button
                    onClick={handleClose}
                    color='error'
                    variant='text'
                    sx={{ marginRight: '20px' }}
                  >
                    Cancel
                  </Button>
                  <Button variant='text' onClick={handleModalSave}>
                    Submit
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Modal>
          <Modal
            open={editModalOpen}
            onClose={handleEditModalClose}
            aria-labelledby='edit-modal-title'
            aria-describedby='edit-modal-description'
          >
            <Box
              sx={{
                position: 'absolute',
                top: '30%',
                left: '40%',
                width: '30%',
              }}
            >
              <Paper>
                <Typography
                  id='edit-modal-title'
                  variant='h6'
                  component='h2'
                  sx={{ padding: '20px' }}
                >
                  Edit Keyword
                </Typography>
                <Divider />
                <Stack spacing={2} sx={{ padding: '20px' }}>
                  <TextField
                    id='keyword'
                    label='Keyword or Phrase'
                    type='text'
                    variant='standard'
                    value={editKeyword.keyword}
                    onChange={inputGroupChangeHandler}
                    fullWidth
                    autoFocus
                    required
                  />
                  <Typography
                    id='modal-settings-section'
                    variant='body2'
                    sx={{ marginTop: '40px' }}
                  >
                    Settings
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          inputProps={{ id: 'exact' }}
                          checked={editKeyword.exact_match}
                          onChange={exactMatchHandler}
                        />
                      }
                      label='Exact Match Only'
                    />
                  </FormGroup>
                  <Divider />
                  <Typography
                    id='edit-modal-title'
                    variant='body2'
                    sx={{ marginTop: '40px' }}
                  >
                    Sources (where to look)
                  </Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          inputProps={{ id: 'reddit' }}
                          checked={editKeyword.reddit}
                          onChange={redditSwitchHandler}
                        />
                      }
                      label='Reddit'
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          inputProps={{ id: 'twitter' }}
                          checked={editKeyword.twitter}
                          onChange={twitterSwitchHandler}
                        />
                      }
                      label='Twitter'
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          inputProps={{ id: 'mastodon' }}
                          checked={editKeyword.mastodon}
                          onChange={mastodonSwitchHandler}
                        />
                      }
                      label='Mastodon'
                    />
                  </FormGroup>
                </Stack>
                <Divider sx={{ marginTop: '20px' }} />
                <Box
                  sx={{
                    textAlign: 'right',
                    padding: '10px',
                  }}
                >
                  <Button
                    onClick={handleEditModalClose}
                    color='error'
                    variant='text'
                    sx={{ marginRight: '20px' }}
                  >
                    Cancel
                  </Button>
                  <Button variant='text' onClick={handleEditModalSave}>
                    Submit
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Modal>
          <ConfirmDialog
            title='Delete Keyword?'
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={() => handleDelete(value)}
          >
            Are you sure you want to delete this keyword? This will delete all
            associated data and mentions (including saved ones).
          </ConfirmDialog>
        </Box>
      )}
    </div>
  );
}

export default Keywords;
