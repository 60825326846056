import * as React from 'react';
import '../App.css';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

function Terms() {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      sx={{
        width: '100%',
        paddingTop: '20px',
        paddingBottom: '20px',
        textAlign: 'left',
        bgcolor: '#f0eded',
      }}
    >
      <Paper sx={{ height: 'auto', width: '70%', padding: '40px' }}>
        <Typography variant='h4' sx={{ paddingBottom: '20px' }}>
          Terms and Conditions
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Introduction
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          These Website Standard Terms and Conditions written on this webpage
          shall manage your use of this website. These Terms will be applied
          fully and affect to your use of this Website. By using this Website,
          you agreed to accept all terms and conditions written in here. You
          must not use this Website if you disagree with any of these Website
          Standard Terms and Conditions.
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Minors or people below 18 years old are not allowed to use this
          Website.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Intellectual Property Rights
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Other than the content you own, under these Terms, Mention List and/or
          its licensors own all the intellectual property rights and materials
          contained in this Website.
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          You are granted limited license only for purposes of viewing the
          material contained on this Website.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Restrictions
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          You are specifically restricted from all of the following
        </Typography>
        <List>
          <ListItem key={1}>
            - publishing any Website material in any other media
          </ListItem>
          <ListItem key={2}>
            - selling, sublicensing and/or otherwise commercializing any Website
            material
          </ListItem>
          <ListItem key={3}>
            - publicly performing and/or showing any Website material
          </ListItem>
          <ListItem key={4}>
            - using this Website in any way that is or may be damaging to this
            Website
          </ListItem>
          <ListItem key={5}>
            - using this Website in any way that impacts user access to this
            Website
          </ListItem>
          <ListItem key={6}>
            - using this Website contrary to applicable laws and regulations, or
            in any way may cause harm to the Website, or to any person or
            business entity
          </ListItem>
          <ListItem key={7}>
            - engaging in any data mining, data harvesting, data extracting or
            any other similar activity in relation to this Website
          </ListItem>
        </List>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Certain areas of this Website are restricted from being access by you
          and Mention List may further restrict access by you to any areas of
          this Website, at any time, in absolute discretion. Any user ID and
          password you may have for this Website are confidential and you must
          maintain confidentiality as well.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Your Content
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          In these Website Standard Terms and Conditions, 'Your Content' shall
          mean any audio, video text, images or other material you choose to
          display on this Website. By displaying Your Content, you grant Mention
          List a non-exclusive, worldwide irrevocable, sub licensable license to
          use, reproduce, adapt, publish, translate and distribute it in any and
          all media.
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Your Content must be your own and must not be invading any
          third-party's rights. Mention List reserves the right to remove any of
          Your Content from this Website at any time without notice.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          No warranties
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          This Website is provided 'as is', with all faults, and Mention List
          express no representations or warranties, of any kind related to this
          Website or the materials contained on this Website. Also, nothing
          contained on this Website shall be interpreted as advising you.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Limitation of liability
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          In no event shall Mention List, nor any of its officers, directors and
          employees, shall be held liable for anything arising out of or in any
          way connected with your use of this Website whether such liability is
          under contract. Mention List, including its officers, directors and
          employees shall not be held liable for any indirect, consequential or
          special liability arising out of or in any way related to your use of
          this Website.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Indemnification
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          You hereby indemnify to the fullest extent Mention List from and
          against any and/or all liabilities, costs, demands, causes of action,
          damages and expenses arising in any way related to your breach of any
          of the provisions of these Terms.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Severability
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          If any provision of these Terms is found to be invalid under any
          applicable law, such provisions shall be deleted without affecting the
          remaining provisions herein.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Variation of Terms
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Mention List is permitted to revise these Terms at any time as it sees
          fit, and by using this Website you are expected to review these Terms
          on a regular basis.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Assignment
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          Mention List is allowed to assign, transfer, and subcontract its
          rights and/or obligations under these Terms without any notification.
          However, you are not allowed to assign, transfer, or subcontract any
          of your rights and/or obligations under these Terms.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Entire Agreement
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          These Terms constitute the entire agreement between Mention List and
          you in relation to your use of this Website, and supersede all prior
          agreements and understandings.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          Governing Law & Jurisdiction
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          These Terms will be governed by and interpreted in accordance with the
          laws of the State of New York, and you submit to the non-exclusive
          jurisdiction of the state and federal courts located in New York for
          the resolution of any disputes.
        </Typography>
        <Typography
          variant='h6'
          sx={{ paddingBottom: '10px', paddingTop: '20px' }}
        >
          No Application Warranties
        </Typography>
        <Typography variant='body1' sx={{ marginBottom: '10px' }}>
          This assistant is provided 'as is', with all faults, and Mention List
          express no representations or warranties, of any kind related to this
          Website or the materials contained on this Website.
        </Typography>
      </Paper>
    </Box>
  );
}

export default Terms;
