const setResponse = (res) => {
  if (res.status === 200) {
    return res;
  } else {
    return {
      status: res.status,
      message: res.data.msg || 'Failed to process request',
      data: res.data,
    };
  }
};

export { setResponse };
