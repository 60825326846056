import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

function SectionHeader(props) {
  return (
    <Box
      sx={{
        textAlign: 'left',
        margin: '20px',
        marginTop: '0px',
        marginBottom: '0px',
      }}
    >
      <Typography variant='body'>{props.section}</Typography>
      <Divider />
    </Box>
  );
}

export default SectionHeader;
