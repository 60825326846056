import * as React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import dayjs from 'dayjs';

import SaveIcon from '@mui/icons-material/Save';
import LinkIcon from '@mui/icons-material/Link';
import CloseIcon from '@mui/icons-material/Close';

import twitterLogo from '../images/twitter32.png';
import redditLogo from '../images/reddit32.png';
import mastodonLogo from '../images/mastodon32.png';

function Mention(props) {
  const generateLink = (data) => {
    if (data.source === 'Reddit') {
      return 'https://reddit.com' + data.url;
    } else if (data.source === 'Mastodon') {
      return data.url;
    } else if (data.source === 'Twitter') {
      return data.url;
    }
  };

  return (
    <Paper
      elevation={1}
      sx={{
        textAlign: 'left',
        minHeight: props.minHeight || '140px',
        width: {
          xs: `calc(100% - (100% - (100%/12)*7.6))`,
          sm: `calc(100% - (100% - (100%/12)*10))`,
          md: `calc(100% - (100% - (100%/12)*11))`,
          lg: '100%',
        },
      }}
      variant='outlined'
    >
      <Stack direction='row'>
        <Box
          sx={{
            textAlign: 'left',
            padding: '10px',
            paddingBottom: '0px',
          }}
        >
          {' '}
          {props.data.source === 'Reddit' ? (
            <img alt='Reddit' src={redditLogo}></img>
          ) : props.data.source === 'Twitter' ? (
            <img alt='Twitter' src={twitterLogo}></img>
          ) : (
            <img alt='Mastodon' src={mastodonLogo}></img>
          )}
        </Box>
        <Box
          sx={{
            height: 'auto',
            width: '100%',
            textAlign: 'right',
            marginTop: '16px',
            marginRight: '14px',
          }}
        >
          <Typography variant='body2'>
            {dayjs.unix(props.data.created_date).format('MM/DD/YYYY')}
          </Typography>
        </Box>
      </Stack>
      <Divider />
      <Stack
        sx={{ marginLeft: '14px', marginTop: '14px', marginRight: '14px' }}
      >
        <Typography
          sx={{
            textAlign: 'left',
            fontSize: 12,
          }}
          variant='caption'
        >
          Post
        </Typography>
        <Typography
          sx={{ textAlign: 'left', wordWrap: 'break-word' }}
          variant='body2'
        >
          {props.data.title}
        </Typography>
        {props.data.source === 'Reddit' ? (
          <div style={{ marginTop: '10px' }}>
            <Typography sx={{ textAlign: 'left' }} variant='caption'>
              Self Text
            </Typography>
            <Typography sx={{ textAlign: 'left' }} variant='body2'>
              {props.data.full_text}
            </Typography>
          </div>
        ) : (
          <></>
        )}
        <Typography
          sx={{ textAlign: 'left', marginTop: '10px' }}
          variant='caption'
        >
          Author
        </Typography>
        <Typography sx={{ textAlign: 'left' }} variant='body2'>
          {props.data.author}
        </Typography>
      </Stack>
      <Stack direction='row'>
        <Box
          sx={{
            width: '100%',
            textAlign: 'right',
            padding: '10px',
          }}
        >
          <IconButton
            color='secondary'
            href={generateLink(props.data)}
            target='_blank'
          >
            <LinkIcon />
          </IconButton>
          <IconButton
            color='primary'
            disabled={props.type === 'saved'}
            onClick={() => props.saveClick(props.data)}
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            color='error'
            disabled={props.type === 'dismissed'}
            onClick={() => props.dismissClick(props.data)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Stack>
    </Paper>
  );
}

export default Mention;
