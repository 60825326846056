import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import LinkIcon from '@mui/icons-material/Link';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import MaterialTable from 'material-table';
import BarLoader from 'react-spinners/BarLoader';

import useData from '../context/dataContext.js';
import useAuth from '../context/authContext.js';
import useUi from '../context/uiContext.js';
import DetailPanel from '../components/detailpanel.js';
import StatPanel from '../components/statpanel.js';
import SectionHeader from '../components/sectionheader.js';
import Mention from '../components/mention.js';

import twitterLogo from '../images/twitter32.png';
import redditLogo from '../images/reddit32.png';
import mastodonLogo from '../images/mastodon32.png';

import { Navigate } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `mention-tab-${index}`,
    'aria-controls': `mention-tabpanel-${index}`,
  };
}

function Mentions() {
  const { keywords, mentions, getMentions, putMention, clearMentions } =
    useData();
  const { user } = useAuth();
  const { setToast, navKeyword, setNavKeyword } = useUi();
  const [keyword, setKeyword] = React.useState('');
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const handleTabChange = (event, newValue) => {
    setLoading(true);
    setValue(newValue);
    setLoading(false);
  };

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
    getMentions(event.target.value);
  };

  const renderDateColumns = (params) => {
    return <Box>{dayjs.unix(params.created_date).format('MM/DD/YYYY')}</Box>;
  };

  const renderSourceColumns = (params) => {
    return (
      <Box>
        <Box sx={{ height: 'auto', width: '32px', textAlign: 'center' }}>
          <img
            alt={params.source}
            src={
              params.source === 'Twitter'
                ? twitterLogo
                : params.source === 'Reddit'
                ? redditLogo
                : mastodonLogo
            }
          ></img>
        </Box>
      </Box>
    );
  };

  const handleDismissClick = (mention) => {
    mention.dismissed = true;
    mention.saved = false;
    putMention(mention._id, mention).then((res) => {
      if (res.status === 200) {
        setToast({
          open: true,
          message: 'Mention dismissed.',
          severity: 'success',
        });
      } else if (res.status === 403) {
        setToast({
          open: true,
          message: 'Mention limit exceeded.',
          severity: 'error',
        });
      } else {
        setToast({
          open: true,
          message: 'Something went wrong, please try again.',
          severity: 'error',
        });
      }
    });
  };

  const handleSaveClick = (mention) => {
    mention.saved = true;
    mention.dismissed = false;
    putMention(mention._id, mention).then((res) => {
      if (res.status === 200) {
        setToast({
          open: true,
          message: 'Mention saved.',
          severity: 'success',
        });
      } else if (res.status === 403) {
        setToast({
          open: true,
          message: 'Saved mention limit exceeded.',
          severity: 'error',
        });
      } else {
        setToast({
          open: true,
          message: 'Something went wrong, please try again.',
          severity: 'error',
        });
      }
    });
  };

  const renderLinkColumns = (params) => {
    return (
      <Box>
        {params.source === 'Reddit' ? (
          <a
            href={'https://www.reddit.com' + params.url}
            target='_blank'
            rel='noreferrer'
          >
            <LinkIcon />
          </a>
        ) : (
          <a href={params.url} target='_blank' rel='noreferrer'>
            <LinkIcon />
          </a>
        )}
      </Box>
    );
  };

  const tableColumns = [
    { title: 'Keyword', field: 'keywordname' },
    { title: 'Source', field: 'source' },
    { title: 'Post', field: 'title' },
    { title: 'Self Text', field: 'full_text' },
    { title: 'Posted Date', field: 'provider_created_date' },
    { title: 'Link', field: 'url' },
  ];

  React.useEffect(() => {
    setLoading(true);
    if (navKeyword !== '') {
      setNavKeyword(navKeyword);
      getMentions(navKeyword);
      setLoading(false);
    } else if (keywords.length > 0) {
      setNavKeyword(keywords[0]._id);
      getMentions(keywords[0]._id);
      setLoading(false);
    }
  }, [keywords, navKeyword]);

  return (
    <div>
      {user.plan === '' ? (
        <Navigate to='/plans' />
      ) : (
        <Box sx={{ width: '100%', paddingLeft: '20px', paddingRight: '20px' }}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  aria-label='mention-tabs-2'
                >
                  <Tab label='New' {...a11yProps(0)} />
                  <Tab label='Saved' {...a11yProps(1)} />
                  <Tab label='Dismissed' {...a11yProps(2)} />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={0} sm={1} md={2} />
            <Grid item xs={12} md={8}>
              <TabPanel value={value} index={0}>
                <MaterialTable
                  isLoading={loading}
                  loadingType='linear'
                  style={{
                    boxShadow: 'none',
                    border: 'none',
                    background: 'none',
                    width: '100%',
                  }}
                  options={{
                    paging: false,
                    showTitle: false,
                    exportButton: true,
                    exportAllData: true,
                    exportFileName: 'Mention_List_Export',
                    search: true,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: {
                      //minWidth: '300px',
                    },
                    padding: 'dense',
                  }}
                  columns={tableColumns}
                  data={mentions ? mentions.new : []}
                  components={{
                    Row: (props) => (
                      <div style={{ marginBottom: '20px' }}>
                        <Mention
                          data={props.data}
                          saveClick={handleSaveClick}
                          dismissClick={handleDismissClick}
                          type='new'
                        />
                      </div>
                    ),
                    Header: () => <></>,
                  }}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <MaterialTable
                  isLoading={loading}
                  loadingType='linear'
                  style={{
                    boxShadow: 'none',
                    border: 'none',
                    background: 'none',
                    width: '100%',
                  }}
                  options={{
                    paging: false,
                    showTitle: false,
                    exportButton: true,
                    exportAllData: true,
                    search: true,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: {
                      //minWidth: '300px',
                    },
                    padding: 'dense',
                  }}
                  columns={tableColumns}
                  data={mentions ? mentions.saved : []}
                  components={{
                    Row: (props) => (
                      <div style={{ marginBottom: '20px' }}>
                        <Mention
                          data={props.data}
                          saveClick={handleSaveClick}
                          dismissClick={handleDismissClick}
                          type='saved'
                        />
                      </div>
                    ),
                    Header: () => <></>,
                  }}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <MaterialTable
                  isLoading={loading}
                  loadingType='linear'
                  style={{
                    boxShadow: 'none',
                    border: 'none',
                    background: 'none',
                    width: '100%',
                  }}
                  options={{
                    paging: false,
                    showTitle: false,
                    exportButton: true,
                    exportAllData: true,
                    search: true,
                    searchFieldAlignment: 'left',
                    searchFieldStyle: {
                      //minWidth: '300px',
                    },
                    padding: 'dense',
                  }}
                  columns={tableColumns}
                  data={mentions ? mentions.dismissed : []}
                  components={{
                    Row: (props) => (
                      <div style={{ marginBottom: '20px' }}>
                        <Mention
                          data={props.data}
                          saveClick={handleSaveClick}
                          dismissClick={handleDismissClick}
                          type='dismissed'
                        />
                      </div>
                    ),
                    Header: () => <></>,
                  }}
                />
              </TabPanel>
            </Grid>
            <Grid item xs={0} sm={1} md={2} />
          </Grid>
        </Box>
      )}
    </div>
  );
}

export default Mentions;
