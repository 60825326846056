import axios from 'axios';
import { authHeader } from './auth-header.js';

import { setResponse } from './_helpers.js';

const API_URL = process.env.REACT_APP_API_URL;

// get all keywords
const getKeywords = () => {
  return axios.get(API_URL + '/keywords', authHeader()).then((res) => {
    return setResponse(res);
  });
};

// post new keyword
const postKeyword = (data) => {
  return axios.post(API_URL + '/keywords', data, authHeader()).then((res) => {
    return setResponse(res);
  });
};

// post new keyword
const postContact = (data) => {
  return axios.post(API_URL + '/contact', data, authHeader()).then((res) => {
    return setResponse(res);
  });
};

// post new keyword
const deleteKeyword = (id) => {
  return axios.delete(API_URL + `/keywords/${id}`, authHeader()).then((res) => {
    return setResponse(res);
  });
};

// put keyword
const putKeyword = (id, data) => {
  return axios
    .put(API_URL + `/keywords/${id}`, data, authHeader())
    .then((res) => {
      return setResponse(res);
    });
};

// get all mentions for a keyword
const getMentions = (keyword_id) => {
  return axios
    .get(API_URL + `/mentions/${keyword_id}`, authHeader())
    .then((res) => {
      return setResponse(res);
    });
};

// get summarized mention data
const getMentionData = () => {
  return axios.get(API_URL + `/mentions/data`, authHeader()).then((res) => {
    return setResponse(res);
  });
};

// get all mentions for a range
const getMentionRangeData = () => {
  return axios.get(API_URL + `/mentions/range`, authHeader()).then((res) => {
    return setResponse(res);
  });
};

// put mention
const putMention = (id, data) => {
  return axios
    .put(API_URL + `/mentions/${id}`, data, authHeader())
    .then((res) => {
      return setResponse(res);
    });
};

const ApiService = {
  getKeywords,
  postKeyword,
  deleteKeyword,
  putKeyword,
  getMentions,
  getMentionData,
  getMentionRangeData,
  putMention,
  postContact,
};
export default ApiService;
