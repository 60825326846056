import * as React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SectionHeader from '../components/sectionheader.js';

function Research() {
  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://cse.google.com/cse.js?cx=019b171442b682bd8';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <SectionHeader section='Research New Keywords' />
      <Paper
        sx={{
          width: '95%',
          height: '800px',
          marginLeft: '20px',
          marginRight: '20px',
          paddingLeft: '30px',
          paddingRight: '30px',
          textAlign: 'left',
          overflow: 'scroll',
        }}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            width: '100%',
            height: 'auto',
            padding: '10px',
            marginTop: '10px',
            color: 'text.secondary',
          }}
        >
          <Typography variant='h6'>
            Enter a search below to see sample keyword results
          </Typography>
        </Box>
        <div className='gcse-search'></div>
      </Paper>
    </Box>
  );
}

export default Research;
