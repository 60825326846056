import * as React from 'react';

import { isEmail, isLength } from 'validator';
import { useStateValidator } from 'react-use';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import useAuth from '../context/authContext.js';
import useUi from '../context/uiContext.js';

import AuthService from '../api/auth.js';

export default function Account() {
  const { user, putUser } = useAuth();
  const Paddle = window.Paddle;

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [plan, setPlan] = React.useState('');
  const [keywordsLeft, setKeywordsLeft] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [password2, setPassword2] = React.useState('');
  const [emailValid] = useStateValidator(email, (email) => isEmail(email));
  const [passwordValid] = useStateValidator(password, (password) =>
    isLength(password, { min: 8, max: undefined })
  );
  const { setToast } = useUi();

  const invalid = () => {
    if (!emailValid) {
      setToast({
        open: true,
        message: 'Invalid email address.',
        severity: 'error',
      });
    } else if (!passwordValid) {
      setToast({
        open: true,
        message: 'Invalid password length.',
        severity: 'error',
      });
    }
  };

  const handleSubmit = () => {
    if (!emailValid) {
      invalid();
    } else {
      putUser(user._id, { name: name, email: email }).then((res) => {
        if (res.status === 200) {
          setToast({
            open: true,
            message: 'Saved user details.',
            severity: 'success',
          });
        } else {
          setToast({ open: true, message: res.message, severity: 'error' });
        }
      });
    }
  };

  const handlePasswordSubmit = () => {
    if (!passwordValid) {
      invalid();
    } else {
      AuthService.resetPassword(user._id, {
        password: password,
        password2: password2,
      }).then((res) => {
        if (res.status === 200) {
          setPassword('');
          setPassword2('');
          setToast({
            open: true,
            message: 'Password reset successfully',
            severity: 'success',
          });
        } else {
          setToast({ open: true, message: res.message, severity: 'error' });
        }
      });
    }
  };

  const openCheckout = () => {
    Paddle.Checkout.open({ product: 766962, email: email });
  };

  React.useEffect(() => {
    setEmail(user.email || '');
    setName(user.name || '');
    setPlan(user.plan || '');
    setKeywordsLeft(user.keywordsleft);
  }, [user]);

  return (
    <Stack sx={{ width: '100%', height: '1000px' }}>
      <Paper sx={{ minWidth: '40%', margin: '20px' }}>
        <Typography sx={{ padding: '16px', textAlign: 'left' }}>
          Basic Information
        </Typography>
        <Divider />
        <Stack spacing={2} sx={{ margin: '30px' }}>
          <TextField
            id='fullname'
            label='Full Name'
            type='text'
            variant='standard'
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            autoFocus
            required
          />
          <TextField
            id='profile-email'
            label='Email'
            type='email'
            variant='standard'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            autoFocus
            required
          />
        </Stack>
        <Divider />
        <Box
          sx={{
            textAlign: 'right',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingRight: '20px',
          }}
        >
          <Button
            variant='outlined'
            color='primary'
            sx={{ height: '36px', width: '100px' }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </Paper>
      <Paper sx={{ minWidth: '40%', margin: '20px' }}>
        <Typography sx={{ padding: '16px', textAlign: 'left' }}>
          Reset Password
        </Typography>
        <Divider />
        <Stack spacing={2} sx={{ margin: '30px' }}>
          <TextField
            id='profile-password'
            label='New Password'
            type='password'
            variant='standard'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
          />
          <TextField
            id='profile-password2'
            label='Confirm New Password'
            type='password'
            variant='standard'
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            fullWidth
            required
          />
        </Stack>
        <Divider />
        <Box
          sx={{
            textAlign: 'right',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingRight: '20px',
          }}
        >
          <Button
            variant='outlined'
            color='primary'
            sx={{ height: '36px', width: '100px' }}
            onClick={handlePasswordSubmit}
          >
            Save
          </Button>
        </Box>
      </Paper>
      <Paper sx={{ minWidth: '40%', margin: '20px' }}>
        <Typography sx={{ padding: '16px', textAlign: 'left' }}>
          Plan and Billing
        </Typography>
        <Divider />
        <Stack spacing={2} sx={{ margin: '30px' }}>
          <TextField
            id='profile-plan'
            label='Plan Type'
            type='text'
            variant='standard'
            value={plan}
            onChange={(e) => setPlan(e.target.value)}
            fullWidth
            disabled
          />
          <TextField
            id='profile-keywords'
            label='Keywords Remaining'
            variant='standard'
            value={keywordsLeft}
            fullWidth
            disabled
          />
        </Stack>
        <Divider />
        <Box
          sx={{
            textAlign: 'right',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingRight: '20px',
          }}
        >
          <Button
            variant='outlined'
            color='primary'
            sx={{ height: '36px', width: '100px' }}
            disabled
            onClick={openCheckout}
          >
            Save
          </Button>
        </Box>
      </Paper>
    </Stack>
  );
}
