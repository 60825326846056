import * as React from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useAuth from '../../context/authContext.js';

export default function Plans() {
  const Paddle = window.Paddle;
  const { user } = useAuth();
  const [plans, setPlans] = React.useState({});

  const paddle_plans = () => {
    if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'dev') {
      return {
        research: 31184,
        startup: 31186,
        scale: 31185,
      };
    } else {
      return {
        research: 777349,
        startup: 777350,
        scale: 777351,
      };
    }
  };

  const openCheckout = (product) => {
    Paddle.Checkout.open({
      email: user.email,
      product: product,
      success: 'https://mentionlist.com/success',
    });
  };

  React.useEffect(() => {
    setPlans(paddle_plans);
  }, []);

  return (
    <Box sx={{ width: '100%', height: 'auto', overflow: 'scroll' }}>
      <Typography sx={{ marginTop: '40px' }} variant='h4'>
        Select a plan to continue
      </Typography>
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        spacing={3}
        sx={{
          width: '100%',
          minHeight: '500px',
          bgcolor: '#f0eded',
          marginTop: '20px',
          paddingTop: '20px',
          paddingBottom: '30px',
        }}
      >
        <Grid item>
          <Paper
            elevation={0}
            sx={{ width: '300px', height: 'auto', padding: '20px' }}
          >
            <Typography variant='h5' sx={{ marginTop: '20px' }}>
              Research
            </Typography>
            <Typography
              color='primary.main'
              variant='h3'
              sx={{ paddingTop: '20px', paddingBottom: '10px' }}
            >
              $10
            </Typography>
            <Typography
              color='primary.main'
              variant='body1'
              sx={{ paddingBottom: '20px' }}
            >
              per month
            </Typography>
            <Divider sx={{ marginBottom: '20px' }} />
            <Stack spacing={1}>
              <Typography variant='body2'>5 Keywords</Typography>
              <Typography variant='body2'>1000 New Mentions</Typography>
              <Typography variant='body2'>Keyword Research</Typography>
              <Typography
                variant='body2'
                sx={{ textDecoration: 'line-through' }}
              >
                Stored Mentions
              </Typography>
              <Typography
                variant='body2'
                sx={{ textDecoration: 'line-through' }}
              >
                Data Export
              </Typography>
            </Stack>
            <Button
              variant='contained'
              sx={{ marginTop: '20px', width: '80%' }}
              onClick={() => {
                openCheckout(plans.research);
              }}
            >
              Select
            </Button>
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{ width: '300px', height: 'auto', padding: '20px' }}
          >
            <Typography variant='h5' sx={{ marginTop: '20px' }}>
              Startup
            </Typography>
            <Typography
              color='primary.main'
              variant='h3'
              sx={{ paddingTop: '20px', paddingBottom: '10px' }}
            >
              $25
            </Typography>
            <Typography
              color='primary.main'
              variant='body1'
              sx={{ paddingBottom: '20px' }}
            >
              per month
            </Typography>
            <Divider sx={{ marginBottom: '20px' }} />
            <Stack spacing={1}>
              <Typography variant='body2'>20 Keywords</Typography>
              <Typography variant='body2'>Unlimited New Mentions</Typography>
              <Typography variant='body2'>Keyword Research</Typography>
              <Typography variant='body2'>500 Stored Mentions</Typography>
              <Typography variant='body2'>Data Export</Typography>
            </Stack>
            <Button
              variant='contained'
              sx={{ marginTop: '20px', width: '80%' }}
              onClick={() => {
                openCheckout(plans.startup);
              }}
            >
              Select
            </Button>
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{ width: '300px', height: 'auto', padding: '20px' }}
          >
            <Typography variant='h5' sx={{ marginTop: '20px' }}>
              Scale
            </Typography>
            <Typography
              color='primary.main'
              variant='h3'
              sx={{ paddingTop: '20px', paddingBottom: '10px' }}
            >
              $75
            </Typography>
            <Typography
              color='primary.main'
              variant='body1'
              sx={{ paddingBottom: '20px' }}
            >
              per month
            </Typography>
            <Divider sx={{ marginBottom: '20px' }} />
            <Stack spacing={1}>
              <Typography variant='body2'>200 Keywords</Typography>
              <Typography variant='body2'>Unlimited New Mentions</Typography>
              <Typography variant='body2'>Keyword Research</Typography>
              <Typography variant='body2'>10,000 Stored Mentions</Typography>
              <Typography variant='body2'>Data Export</Typography>
            </Stack>
            <Button
              variant='contained'
              sx={{ marginTop: '20px', width: '80%' }}
              onClick={() => {
                openCheckout(plans.scale);
              }}
            >
              Select
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
