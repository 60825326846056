import * as React from 'react';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

import { styled } from '@mui/material/styles';

import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import logo from '../../images/logo_1.png';

const Img = styled('img')({
  maxHeight: '100%',
  paddingBottom: '9px',
});

export default function MainLayout() {
  const [showHeader, setShowHeader] = React.useState(true);

  const location = useLocation();

  const navPages = ['About', 'Solutions', 'Pricing'];
  const authPages = ['Sign Up', 'Login'];

  React.useEffect(() => {
    if (location.pathname.includes('login')) {
      setShowHeader(false);
    } else if (location.pathname.includes('register')) {
      setShowHeader(false);
    } else if (location.pathname.includes('forgot')) {
      setShowHeader(false);
    } else if (location.pathname.includes('reset')) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [location.pathname]);

  return (
    <div>
      {showHeader ? (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position='static'>
            <Container maxWidth='xl'>
              <Toolbar disableGutters>
                <Link
                  href='/'
                  sx={{
                    textDecoration: 'none',
                    color: 'inherit',
                    display: { xs: 'none', md: 'flex' },
                    mr: 2,
                    ml: '30px',
                  }}
                >
                  <Img alt='mention list' src={logo} />
                </Link>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {navPages.map((page) => (
                    <HashLink
                      smooth
                      to={{ pathname: '/', hash: '#' + page.toLowerCase() }}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        marginLeft: '16px',
                      }}
                    >
                      <Button color='inherit'>{page}</Button>
                    </HashLink>
                  ))}
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'flex-end',
                    mr: '100px',
                  }}
                >
                  {authPages.map((page) => (
                    <RouterLink
                      to={
                        page === 'Sign Up'
                          ? 'register'
                          : page.toLowerCase().replace(' ', '')
                      }
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        marginLeft: '16px',
                      }}
                    >
                      <Button color='inherit'>{page}</Button>
                    </RouterLink>
                  ))}
                </Box>
                <Link
                  href='/'
                  sx={{
                    textDecoration: 'none',
                    color: 'inherit',
                    display: { xs: 'flex', md: 'none' },
                    mr: 2,
                  }}
                >
                  <Img alt='mention list' src={logo} />
                </Link>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: 'flex', md: 'none' },
                    justifyContent: 'flex-end',
                  }}
                >
                  {authPages.map((page) => (
                    <RouterLink
                      to={
                        page === 'Sign Up'
                          ? 'register'
                          : page.toLowerCase().replace(' ', '')
                      }
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        marginLeft: '16px',
                      }}
                    >
                      <Button color='inherit'>{page}</Button>
                    </RouterLink>
                  ))}
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
          <Outlet />
        </Box>
      ) : (
        <Outlet />
      )}
    </div>
  );
}
