import * as React from 'react';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';

import MaterialTable from 'material-table';
import { MTableToolbar } from 'material-table';

import StatPanel from '../components/statpanel.js';
import Keyword from '../components/keyword.js';
import SectionHeader from '../components/sectionheader.js';
import useData from '../context/dataContext.js';
import useAuth from '../context/authContext.js';
import useUi from '../context/uiContext.js';
import ConfirmDialog from '../components/confirmdialog.js';

import { Navigate, Link } from 'react-router-dom';

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Table } from '@mui/material';

dayjs.extend(dayjsPluginUTC);

function Dashboard() {
  const {
    keywords,
    keywordMentions,
    getKeywords,
    postKeyword,
    putKeyword,
    deleteKeyword,
    mentionData,
    mentionRangeData,
    getMentionData,
    getMentionRangeData,
  } = useData();

  const { user } = useAuth();
  const [chartData, setChartData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [savedMentions, setSavedMentions] = React.useState('');
  const [remainingSavedMentions, setRemainingSavedMentions] =
    React.useState('');

  const [value, setValue] = React.useState(0);
  const [newKeyword, setNewKeyword] = React.useState('');
  const [reddit, setReddit] = React.useState(false);
  const [twitter, setTwitter] = React.useState(false);
  const [mastodon, setMastodon] = React.useState(false);
  const [exactMatch, setExactMatch] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [editKeyword, setEditKeyword] = React.useState({});
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const { setToast } = useUi();

  const columns = [{ title: 'Keyword', field: 'keyword' }];

  const handleDelete = (row) => {
    deleteKeyword(row._id).then((res) => {
      if (res.status === 200) {
        setToast({
          open: true,
          message: 'Keyword deleted successfully.',
          severity: 'success',
        });
      } else {
        setToast({
          open: true,
          message: res.message,
          severity: 'error',
        });
      }
    });
  };

  const handleDeleteConfirm = (row) => {
    setValue(row);
    setConfirmOpen(true);
  };

  // NEW KEYWORD MODAL //

  const redditChange = () => {
    setReddit(!reddit);
  };

  const twitterChange = () => {
    setTwitter(!twitter);
  };

  const mastodonChange = () => {
    setMastodon(!mastodon);
  };

  const matchChange = () => {
    setExactMatch(!exactMatch);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setReddit(false);
    setTwitter(false);
    setMastodon(false);
    setNewKeyword('');
    setExactMatch(false);
  };

  const handleModalSave = () => {
    postKeyword({
      keyword: newKeyword,
      reddit: reddit,
      twitter: twitter,
      mastodon: mastodon,
      exact_match: exactMatch,
    }).then((res) => {
      if (res.status === 200) {
        setToast({
          open: true,
          message: 'Keyword created successfully.',
          severity: 'success',
        });
        handleClose();
      } else if (res.status === 403) {
        setToast({
          open: true,
          message: 'Keyword limit exceeded.',
          severity: 'error',
        });
        handleClose();
      } else {
        setToast({
          open: true,
          message: 'Server error, please try again.',
          severity: 'error',
        });
        handleClose();
      }
    });
  };

  // NEW KEYWORD MODAL END //

  // OVERVIEW DATA //
  const formatChartData = (data) => {
    const endDate = dayjs.utc();
    var chartRange = {};

    var d = 13;
    while (d >= 0) {
      chartRange[endDate.subtract(d, 'days').format('MM/DD')] = 0;
      d--;
    }

    const formattedData = {};
    data.map((i) => {
      formattedData[dayjs(i.found_date).format('MM/DD')] = i.count;
      return {};
    });
    const mergedData = { ...chartRange, ...formattedData };
    var finalData = [];
    for (let i in mergedData) {
      finalData.push({ found_date: i, count: mergedData[i] });
    }

    // set chart, sort data on found_date
    setChartData(
      finalData.sort(function (a, b) {
        return a.found_date - b.found_date;
      })
    );
  };
  // OVERVIEW DATA END //

  // EDIT MODAL //
  const handleEditModalOpen = (row) => {
    setEditKeyword(row);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setEditKeyword({});
  };

  const handleEditModalSave = () => {
    putKeyword(editKeyword._id, editKeyword).then((res) => {
      if (res.status === 200) {
        setToast({
          open: true,
          message: 'Keyword updated.',
          severity: 'success',
        });
      } else {
        setToast({
          open: true,
          message: res.message,
          severity: 'error',
        });
      }
    });
    handleEditModalClose();
  };

  const inputGroupChangeHandler = (event) => {
    setEditKeyword((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  };

  const redditSwitchHandler = (event) => {
    setEditKeyword((prevState) => ({
      ...prevState,
      reddit: event.target.checked,
    }));
  };

  const twitterSwitchHandler = (event) => {
    setEditKeyword((prevState) => ({
      ...prevState,
      twitter: event.target.checked,
    }));
  };

  const mastodonSwitchHandler = (event) => {
    setEditKeyword((prevState) => ({
      ...prevState,
      mastodon: event.target.checked,
    }));
  };

  const exactMatchHandler = (event) => {
    setEditKeyword((prevState) => ({
      ...prevState,
      exact_match: event.target.checked,
    }));
  };

  // EDIT MODAL END //

  React.useEffect(() => {
    getKeywords();
    getMentionData();
    getMentionRangeData();
  }, []);

  React.useEffect(() => {
    setSavedMentions(mentionData.saved_mentions);
    setRemainingSavedMentions(
      user.saved_mentions_licensed - mentionData.saved_mentions
    );
    formatChartData(mentionRangeData);
    setLoading(false);
  }, [user, keywords, mentionData, mentionRangeData]);

  const customizedTick = (data) => {
    if (data.length > 15) {
      return data.slice(0, 15) + '...';
    } else {
      return data;
    }
  };

  return (
    <div>
      {user.plan === '' ? (
        <Navigate to='/plans' />
      ) : (
        <Box>
          {keywords.length === 0 ? (
            <Grid item xs={12}>
              <Alert
                severity='info'
                sx={{ marginTop: '-20px', marginBottom: '10px' }}
              >
                <Typography style={{ color: 'inherit' }}>
                  It looks like you don't have any keywords to track yet. Click
                  the button below to create one.
                </Typography>
              </Alert>
            </Grid>
          ) : (
            <div />
          )}
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <SectionHeader section='Keywords' />
            </Grid>
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={8}>
              <Box sx={{ height: '10px' }} />
              <MaterialTable
                style={{
                  boxShadow: 'none',
                  border: 'none',
                  background: 'none',
                  marginRight: '20px',
                }}
                options={{
                  paging: false,
                  showTitle: false,
                  searchFieldAlignment: 'left',
                  searchFieldStyle: {
                    marginLeft: '6px',
                    minWidth: '300px',
                  },
                  rowStyle: {
                    fontSize: 15,
                  },
                  padding: 'dense',
                  actionsColumnIndex: -1,
                }}
                columns={columns}
                data={keywords}
                components={{
                  Row: (props) => (
                    <div style={{ marginBottom: '20px' }}>
                      <Keyword
                        data={props.data}
                        deleteClick={handleDeleteConfirm}
                        editClick={handleEditModalOpen}
                        counts={keywordMentions}
                      />
                    </div>
                  ),
                  Header: () => <></>,
                  Toolbar: (props) => (
                    <Box sx={{ width: '100%', marginBottom: '12px' }}>
                      <Grid
                        container
                        spacing={0}
                        sx={{ justify: 'flex-end', alignItems: 'center' }}
                      >
                        <Grid item xs={12} md={9}>
                          <MTableToolbar {...props} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Button
                            variant='contained'
                            onClick={handleOpen}
                            sx={{
                              height: '40px',
                              width: '90%',
                              marginLeft: '18px',
                            }}
                          >
                            + Add Keyword
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={0} md={2} />
          </Grid>
        </Box>
      )}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby='new-modal-title'
        aria-describedby='new-modal-description'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ minWidth: '30%' }}>
          <Paper>
            <Typography
              id='new-modal-header'
              variant='h6'
              component='h2'
              sx={{ padding: '20px' }}
            >
              Create a Keyword
            </Typography>
            <Divider />
            <Stack spacing={2} sx={{ padding: '20px' }}>
              <TextField
                id='keyword'
                label='Keyword or Phrase'
                type='text'
                variant='standard'
                value={newKeyword}
                onChange={(e) => setNewKeyword(e.target.value)}
                fullWidth
                autoFocus
                required
              />
              <Typography
                id='new-modal-example-text'
                variant='caption'
                component='body'
              >
                example: "mentionlist.com"
              </Typography>
              <Typography
                id='new-modal-settings-section'
                variant='body2'
                sx={{ marginTop: '40px' }}
              >
                Settings
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ id: 'exact' }}
                      checked={exactMatch}
                      onChange={matchChange}
                    />
                  }
                  label='Exact Match Only'
                />
              </FormGroup>
              <Divider />
              <Typography
                id='new-modal-sources'
                variant='body2'
                sx={{ marginTop: '30px' }}
              >
                Sources (where to look)
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={<Switch checked={reddit} onChange={redditChange} />}
                  label='Reddit'
                />
                <FormControlLabel
                  control={
                    <Switch checked={twitter} onChange={twitterChange} />
                  }
                  label='Twitter'
                />
                <FormControlLabel
                  control={
                    <Switch checked={mastodon} onChange={mastodonChange} />
                  }
                  label='Mastodon'
                />
              </FormGroup>
            </Stack>
            <Divider sx={{ marginTop: '20px' }} />
            <Box
              sx={{
                textAlign: 'right',
                padding: '10px',
              }}
            >
              <Button
                onClick={handleClose}
                color='error'
                variant='text'
                sx={{ marginRight: '20px' }}
              >
                Cancel
              </Button>
              <Button variant='text' onClick={handleModalSave}>
                Submit
              </Button>
            </Box>
          </Paper>
        </Box>
      </Modal>
      <Modal
        open={editModalOpen}
        onClose={handleEditModalClose}
        aria-labelledby='edit-modal-title'
        aria-describedby='edit-modal-description'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ minWidth: '30%' }}>
          <Paper>
            <Typography
              id='edit-modal-title'
              variant='h6'
              component='h2'
              sx={{ padding: '20px' }}
            >
              Edit Keyword
            </Typography>
            <Divider />
            <Stack spacing={2} sx={{ padding: '20px' }}>
              <TextField
                id='keyword'
                label='Keyword or Phrase'
                type='text'
                variant='standard'
                value={editKeyword.keyword}
                onChange={inputGroupChangeHandler}
                fullWidth
                autoFocus
                required
              />
              <Typography
                id='modal-settings-section'
                variant='body2'
                sx={{ marginTop: '40px' }}
              >
                Settings
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ id: 'exact' }}
                      checked={editKeyword.exact_match}
                      onChange={exactMatchHandler}
                    />
                  }
                  label='Exact Match Only'
                />
              </FormGroup>
              <Divider />
              <Typography
                id='edit-modal-title'
                variant='body2'
                sx={{ marginTop: '40px' }}
              >
                Sources (where to look)
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ id: 'reddit' }}
                      checked={editKeyword.reddit}
                      onChange={redditSwitchHandler}
                    />
                  }
                  label='Reddit'
                />
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ id: 'twitter' }}
                      checked={editKeyword.twitter}
                      onChange={twitterSwitchHandler}
                    />
                  }
                  label='Twitter'
                />
                <FormControlLabel
                  control={
                    <Switch
                      inputProps={{ id: 'mastodon' }}
                      checked={editKeyword.mastodon}
                      onChange={mastodonSwitchHandler}
                    />
                  }
                  label='Mastodon'
                />
              </FormGroup>
            </Stack>
            <Divider sx={{ marginTop: '20px' }} />
            <Box
              sx={{
                textAlign: 'right',
                padding: '10px',
              }}
            >
              <Button
                onClick={handleEditModalClose}
                color='error'
                variant='text'
                sx={{ marginRight: '20px' }}
              >
                Cancel
              </Button>
              <Button variant='text' onClick={handleEditModalSave}>
                Submit
              </Button>
            </Box>
          </Paper>
        </Box>
      </Modal>
      <ConfirmDialog
        title='Delete Keyword?'
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={() => handleDelete(value)}
      >
        Are you sure you want to delete this keyword? This will delete all
        associated data and mentions (including saved ones).
      </ConfirmDialog>
    </div>
  );
}

export default Dashboard;
