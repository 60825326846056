import * as React from 'react';

import './App.css';

//Layouts
import MainLayout from './components/layouts/mainLayout.js';
import PublicLayout from './components/layouts/publicLayout.js';

//Context
import { AuthProvider, RequireAuth } from './context/authContext.js';
import { DataProvider } from './context/dataContext.js';
import { UiProvider } from './context/uiContext.js';

//Authenticated Views
import Dashboard from './views/dashboard.js';
import Keywords from './views/keywords.js';
import Mentions from './views/mentions.js';
import Research from './views/research.js';
import Notifications from './views/notifications.js';
import Account from './views/account.js';
import NoMatch from './views/nomatch.js';
import Plans from './components/auth/plans.js';
import Contact from './views/contact.js';

//Public Views
import Landing from './views/landing.js';
import Login from './components/auth/login.js';
import Register from './components/auth/register.js';
import Privacy from './views/privacy.js';
import Terms from './views/terms.js';
import Forgot from './components/auth/forgot.js';
import Reset from './components/auth/reset.js';
import GlobalToast from './components/globaltoast.js';

import { Routes, Route } from 'react-router-dom';

import { GoogleOAuthProvider } from '@react-oauth/google';
import ReactGA from 'react-ga4';
import Success from './components/auth/paymentSuccess';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_KEY}>
      <UiProvider>
        <AuthProvider>
          <DataProvider>
            <div className='App'>
              <GlobalToast />
              <Routes>
                <Route path='/' element={<PublicLayout />}>
                  <Route index element={<Landing />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/register' element={<Register />} />
                  <Route path='/privacy' element={<Privacy />} />
                  <Route path='/terms' element={<Terms />} />
                  <Route path='/forgot' element={<Forgot />} />
                  <Route path='/reset/:token' element={<Reset />} />
                  <Route path='*' element={<NoMatch />} />
                </Route>

                <Route element={<MainLayout />}>
                  <Route
                    index
                    element={
                      <RequireAuth>
                        <Dashboard />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/dashboard'
                    element={
                      <RequireAuth>
                        <Dashboard />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/keywords'
                    element={
                      <RequireAuth>
                        <Keywords />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/mentions'
                    element={
                      <RequireAuth>
                        <Mentions />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/research'
                    element={
                      <RequireAuth>
                        <Research />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/notifications'
                    element={
                      <RequireAuth>
                        <Notifications />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/account'
                    element={
                      <RequireAuth>
                        <Account />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/plans'
                    element={
                      <RequireAuth>
                        <Plans />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/contact'
                    element={
                      <RequireAuth>
                        <Contact />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path='/success'
                    element={
                      <RequireAuth>
                        <Success />
                      </RequireAuth>
                    }
                  />
                </Route>
              </Routes>
            </div>
          </DataProvider>
        </AuthProvider>
      </UiProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
