import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#2F4858',
    },
    secondary: {
      main: '#5AAA95',
    },
    warning: {
      main: '#DB5A42',
    },
    error: {
      main: '#C91844',
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;

/*
Charcoal: #2F4858
Queen Blue: #33658A
Dark Sky Blue: #86BBD8


*/
