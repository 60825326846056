export function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.access_token) {
    return {
      headers: {
        Authorization: 'Bearer ' + user.access_token,
      },
    };
  } else {
    return {};
  }
}

export function authResetHeader(token) {
  return {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
}

export function authNodeHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.accessToken) {
    // for Node.js Express back-end
    return { 'x-access-token': user.accessToken };
  } else {
    return {};
  }
}
