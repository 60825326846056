import * as React from 'react';

import useAuth from './authContext.js';
import ApiService from '../api/api.js';

const dataContext = React.createContext();

function useData() {
  const [keywords, setKeywords] = React.useState([]);
  const [keywordMentions, setKeywordMentions] = React.useState([]);
  const [mentions, setMentions] = React.useState([]);
  const [mentionRangeData, setMentionRangeData] = React.useState([]);
  const [mentionData, setMentionData] = React.useState({
    total_mentions: 0,
    mentions_this_week: 0,
    saved_mentions: 0,
    most_common: '-',
    most_common_count: 0,
  });

  const { authed, user, getUser, logout } = useAuth();

  return {
    keywords,
    keywordMentions,
    mentions,
    mentionData,
    mentionRangeData,
    getKeywords() {
      return new Promise((res) => {
        ApiService.getKeywords().then((res) => {
          if (res.status === 401) {
            logout();
          }
          setKeywords(res.data.keywords);
          setKeywordMentions(res.data.keyword_mentions);
        });
        res();
      });
    },
    deleteKeyword(id) {
      return new Promise((res) => {
        const r = ApiService.deleteKeyword(id).then((res) => {
          setKeywords(res.data);
          getUser();
          return res;
        });
        res(r);
      });
    },
    postKeyword(payload) {
      return new Promise((res) => {
        const r = ApiService.postKeyword(payload).then((res) => {
          if (res.data.msg === 'no keywords left') {
            return { status: 403 };
          } else if (res.status === 200) {
            setKeywords(res.data.keywords);
            setKeywordMentions(res.data.keyword_mentions);
            getUser();
            return { status: 200 };
          } else {
            return { status: 400 };
          }
        });
        res(r);
      });
    },
    putKeyword(id, data) {
      return new Promise((res) => {
        const r = ApiService.putKeyword(id, data).then((res) => {
          setKeywords(res.data);
          return res;
        });
        res(r);
      });
    },
    getMentions(keyword) {
      return new Promise((res) => {
        ApiService.getMentions(keyword).then((res) => {
          if (res.status === 401) {
            logout();
          }

          setMentions(res.data);
          //filterMentions(res);
        });
        res();
      });
    },
    getMentionData() {
      return new Promise((res) => {
        ApiService.getMentionData().then((res) => {
          if (res.status === 401) {
            logout();
          }
          setMentionData(res.data);
        });
        res();
      });
    },
    getMentionRangeData() {
      return new Promise((res) => {
        ApiService.getMentionRangeData().then((res) => {
          if (res.status === 401) {
            logout();
          }
          setMentionRangeData(res.data);
        });
        res();
      });
    },
    putMention(id, data) {
      return new Promise((res) => {
        const r = ApiService.putMention(id, data).then((res) => {
          if (res.data.msg === 'no mentions left') {
            return { status: 403 };
          } else if (res.status === 200) {
            //filterMentions(res);
            getUser();
            setMentions(res.data);
            return { status: 200 };
          } else {
            return { status: 400 };
          }
        });
        res(r);
      });
    },
  };
}

export function DataProvider({ children }) {
  const data = useData();

  return <dataContext.Provider value={data}>{children}</dataContext.Provider>;
}

export default function DataConsumer() {
  return React.useContext(dataContext);
}
